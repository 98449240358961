.form {
	display: block;
	width: 100%;
	max-width: 100%;

	* { max-width: 100%; }
}

// forms on the 'doors' pages
.form_indoor {
	display: block;
	width: 100%;
	max-width: 900px;
	margin: auto; 
	margin-top: 40px;
	margin-bottom: 50px;

	* { max-width: 100%; }

	@include phones {
		.button__colored.button_large {
			width: 100%;
			min-width: auto;
			padding: 0;
		}
	}
}

.form__block_one {
	width: 100%;

	& > * {
		width: 100%;
		max-width: 100%;
	}
}

.form__block_two {
	display: flex;
	width: 100%;

	& > * { flex: 1 1 50%; }

	@include tablets { flex-direction: column; }

	@include phones {
		.input-underline { max-width: 70%; }
	}
}

.form__block_underline {
	margin-bottom: 35px;
	border-bottom: 1px solid $grey;
}


.form__block_column {
	display: flex;
	flex-direction: column;
	flex: 1 1;

	margin-bottom: 35px;

	& > * {
		width: 100%;
	}

	.wpcf7-not-valid + *,
	.invalid + * {
		@extend .invalid;
	}

	@include phones {
		margin-bottom: 0;
		display: -webkit-box;
	}
}


.form__block_assets {
	@extend .form__block_column;
	@extend .mr;

	flex: 0 0 31%;

	& + label[for=assent] {
		flex: 1 1 66%;
	}
}


.form__line {
	display: flex;
	width: 100%;
	
	& > * {
		margin-bottom: 35px;
	}

	@include medium {
		& > * {
			flex-shrink: 1;
			flex-basis: 30%;
			max-width: 30%;
			width: auto;
		}
	}

	@include tablets {
		flex-wrap: wrap;
		justify-content: space-between;

		& > * {
			flex-shrink: 1;
			flex-grow: 1;
			flex-basis: 48%;
			max-width: 48%;
			margin-right: 0;
			width: auto;

		}
	}

	@include phones {
		& > * {
			flex-basis: 100%;
			max-width: 100%;
		}
	}
}

.form__line_two {
	@extend .form__line;

	@include medium {
		& > *:first-child {
			flex-shrink: 1;
			flex-basis: 30%;
			max-width: 30%;
			width: auto;
		}

		& > *:last-child {
			flex: 1 1 100%;
			max-width: 70%;
			width: auto;
		}
	}

	@include tablets {
		flex-wrap: wrap;

		& > *:first-child,
		& > *:last-child {
			flex-shrink: 1;
			flex-grow: 1;
			flex-basis: 100%;
			max-width: 100%;
			margin-right: 0;
			width: auto;
		}

		& > *:first-child { margin-bottom: 5px; }

		select { width: 48%; }
	}

	@include phones {
		select { width: 100%; }
	}
}

// Contact Us form
.form__contacts {
	input,
	textarea,
	select {
		//margin-bottom: 35px;
	}

	.button_large { min-width: auto; }

	.form__block_two {
		& > *:first-child { margin-right: 15px; }
		& > *:last-child  { margin-left: 15px; }

		@include phones {
			& > *:first-child { margin-right: 0; }
			& > *:last-child  {
				margin-left: 0;
			}

			& > * {
				width: 100%;
				max-width: 100%; 
			}
		}
	}

	@include phones {
		& > *:last-child {
			margin-top: 30px; 
		}
	}
}

.form__contacts_two {
	display: flex;
	justify-content: space-between;
	width: 100%;

	& > * {
		flex: 0 1 48%;
		max-width: 48%;
	}

	@include phones {
		flex-direction: column;

		& > * {
			width: 100%;
			max-width: 100%; 
		}

		.input {
			margin-bottom: 15px;
		}
	}
}