.button {
	@extend %input;
	padding-top: 0;
	padding-bottom: 0;
	text-align: center;
	text-transform: uppercase;
	text-shadow: 1px 1px 3px rgba($black, .5);

	transition: background-color .1s ease-out, color .1s ease-out, border-color .1s ease-out, transform .5s ease-in .2s !important;

	&:hover {
		text-decoration: none;
	}
}

.button__colored,
a.button__colored {
	@extend .button;

	background-color: $banner;
	border-color: $banner;
	color: $white;

	&:hover {
		color: $banner;
		border-color: $banner;
		background-color: $white;
		text-shadow: none;
	}
}

.button__colored_invert {
	@extend .button;

	color: $banner;
	border-color: $banner;
	background-color: $white;
	text-shadow: none;

	&:hover {
		background-color: $banner;
		border-color: $banner;
		color: $white;
	}
}

.button__foo {
	@extend .button__colored_invert;
	border-color: $white;

	&:hover {
		text-shadow: 1px 1px 3px rgba($black, .3);
	}
}

.button__white {
	@extend .button;

	color: $banner;
	border-color: $banner;
	background-color: rgba($banner, 0);
	text-shadow: none;

	&:hover {
		background-color: $banner;
		border-color: $banner;
		color: $white;
		text-shadow: 1px 1px 3px rgba($black, .5);
	}
}

.button__light {
	@extend .button;

	color: $white;
	border-color: $white;
	background-color: rgba($banner, 0);

	text-shadow: none;

	&:hover {
		background-color: $banner;
		//border-color: $banner;
		color: $white;
		text-shadow: 1px 1px 3px rgba($black, .5);
	}
}


.button_large {
	height: 56px;
	line-height: 54px;
	min-width: 300px;
}

.button_send,
a.button_send {
	width: 200px;
	height: 48px;
	line-height: 46px;
	font-size: 20px;
}

.button_high {
	padding-top: 7px;
	padding-bottom: 7px;
}

.button_big,
a.button_big  {
	font-size: 22px;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 60px;
	padding-right: 60px;
}