.numbers {
	@extend .blocks_inline;
	align-items: stretch;
	margin-left: -$small-mgr;
	margin-right: -$small-mgr;

	@include large {
		flex-wrap: wrap;
		margin: 0;
	}
}

.numbers__item {
	position: relative;
	margin-top: $small-mgr;
	padding: 0 $small-mgr;
	text-align: center;

	.h1 {
		color: $accent;
		font-weight: bold; 
		white-space: nowrap;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		top: 11%;
		right: 0;
		height: 74%;
		width: 2px;
		background: $accent;
	}

	&:last-child::after { content: none; }

	@include large {
		flex: 1 1 50%;

		&:nth-child(2n)::after { content: none; }
	}

	@include tablets {
		padding: 0 ($small-mgr / 2);
	}

	@include l-phones {
		flex-basis: 100%;

		//&::after { content: none; }
		&:nth-child(2n)::after { content: ""; }
		&::after {
			top: auto;
			bottom: -20px;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			right: auto;
			width: 150px;
			height: 2px;
		}
	}
}

.num {
	display: inline-block;
	width: 75px;
	text-align: right;

	@include l-phones {
		width: auto;
	}
}

.num_nopercent {
	text-align: center;
}