.input.invalid_required,
.input.invalid {
	border-color: $error;
}

.error-message {
	font-size: 11px;
	font-weight: bold;
	color: $error;
}

.result_error,
.result_success {
	margin: 30px 0;
	text-align: center;
	font-size: 1.3em;
}

.result_error   { color: $error; }
.result_success { color: $accent; }

.thanks-msg {
	font-size: 24px!important;
	border: none!important;
}