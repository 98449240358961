.google-map {
	position: relative;
	display: block;

	@include tablets { min-height: 500px; }
}

#GMAP {
	width: 100%;
	height: 100%;
	//min-height: 600px; 

	@include tablets { min-height: 600px;}
	@include phones  { height: 60vh; }
}

.google-map__content {
	position: absolute;
	display: block;
	bottom: 60px;
	right: 60px;
	max-width: 255px;
	padding: 27px 25px;
	font-size: 14px;
	background: $white;
	box-shadow: 2px 2px 10px -1px rgba($black, .5);

	@include phones {
		right: 30px;
		left: 30px;
		max-width: none;
	}

	h3 {
		font-family: $g;
		font-size: 16px;
	}
}