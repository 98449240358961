// VARIABLES

// media queries
$phones: 480px;
$l-phones: 575px;
$tablets: 767px;
$medium: 991px;
$large: 1199px;
$x-large: 1439px;

// colors
$white:  #fff;
$black:  #000;
$grey:   #999;
$arblock:#fafafa;
$sub:    #686868;
$footer: #262626;
$banner: #b18a3e;
$accent: #189048;

$error: #c70000;
$error-light: #e85c41;

// sizes
$content-max: 1270px;
$basic-mrg: 80px;
$small-mgr: 60px;
$font-basic: 18px;
$font-h1: 40px;
$font-h2: 27px;
$font-h3: 20px;
$input: 28px;
$icon-d: 50px;

// fonts
$fantasy: "Jellyka", "Marker Felt", "Comic Sans MS", fantasy;