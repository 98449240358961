// GLOBAL STYLES for all site
@include fontRbi("OpenSans", "../fonts/open-sans/opensans");
@include fontRbi("Merriweather", "../fonts/merriweather/Merriweather");
@include font("Jellyka", "../fonts/jellyka/saint-andrews_queen-webfont");
@include font("FontAwesome", "../fonts/fontawesome/fontawesome-webfont");

@font-face {
	font: {
		family: "Merriweather Italic";
		weight: normal;
		style: italic;
	}
	src: url("../fonts/merriweather/Merriweather_italic.woff2") format("woff2");
}

// Google fonts
@import url('https://fonts.googleapis.com/css?family=Rubik');


$os: "OpenSans";
$g: "Merriweather";
$j: "Jellyka";
$fa: "FontAwesome";

$ci: "Merriweather Italic";

$r: "Rubik";

%Rubik {
	font-family: $r;
	font-weight: bold;
}

* {
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
}

html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden; // for IE
}

html {
	overflow: hidden;
}

body {
	line-height: 1.5;
	font-size: $font-basic;
	@extend %gothic;
	font-family: $os;
}

//a,
//a:hover,
// a:visited { color: $banner; }

/*a[href^=tel] {
	color: inherit;
	text-decoration:inherit;
}*/

img { max-width: 100%; }