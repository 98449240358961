.team {
	margin-top: 75px;

	@include tablets {
		margin-top: 30px;
	}
}

.team__card {
	display: flex;
	width: 100%;
	justify-content: space-between;

	&:not(:first-of-type) {
		margin-top: 115px;

		@include tablets {
			margin-top: 10vw;
		}
	}

	/*.connect-list {
		justify-content: center;
		& > * { margin: 0 5px; }
		& > *:first-child { margin-left:  0!important; }
		& > *:last-child  { margin-right: 0!important; }
		& > *:not(:last-child) { margin-left: auto; }
		& > *:not(:first-child) { margin-right: auto; }
	}*/

	@include phones {
		flex-direction: column;
	}
}

.team__card:nth-of-type(2n) {
	flex-direction: row-reverse;

	.team__man { text-align: left; }
	.team__contacts { flex-direction: row-reverse; }
	.team__pic {
		img {
			margin-right: 0;
			margin-left: auto;
		}
	}

	/*.connect-list {
		justify-content: flex-start;
		& > *:first-child { margin-left: 0; }
	}*/

	@include tablets {
		.team__contacts {
			flex-direction: column;
			align-items: flex-start;
		}		
	}

	@include phones {
		flex-direction: column;

		.team__pic {
			img { margin-right: auto; }
		}

		.team__contacts { align-items: center; }
	}
}

.team__pic {
	flex: 1 1 40%;
	align-self: flex-start;
	
	img {
		display: block;
		width: calc(100% - 4.5vw);
		max-width: 360px;
		margin-right: auto;

		@include tablets {
			width: calc(100% - 30px);
			max-width: auto;
		}

		@include phones {
			width: 100%;
			max-width: 300px;
			margin: auto;
			margin-bottom: 30px;
		}
	}

	@include tablets {
		flex: 1 1 50%;
	}

	@include phones {
		flex: 100%;
		width: 100%;
	}
}

.team__man {
	flex: 1 1 60%;
	font-family: $os;
	color: $black;

	text-align: right;

	@include tablets {
		flex: 0 0 50%;
	}

	@include phones {
		flex: 100%;
		text-align: left;
	}
}

.team__name,
.team__office,
.team__about {
	display: block;
	margin-bottom: 25px;
	margin-top: 0;
	padding: 0;
	font-size: 22px;
	line-height: 1;
	color: $black;
	text-decoration: none;

	transition: color .2s ease;

	@include tablets {
		font-size: 16px;
	}
}

.team__name {
	font-size: 23px;
	font-family: $g;
	font-weight: bold;
	text-transform: uppercase;

	@include tablets {
		font-size: 20px;
	}

	@include phones {
		text-align: center;
	}
}

.team__office {
	font-weight: bold;

	@include phones {
		text-align: center;
	}
}
a[class^=team]:hover {
	color: $banner;
}

.team__about {
	line-height: 1.5;
}

.team__contacts {
	display: flex;
	justify-content: flex-end;
	font-family: $os;
	font-weight: 700;
	min-height: 200px;

	@include tablets {
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: flex-end;
	}

	@include phones {
		align-items: center;

		min-height: auto;
	}
}

.team__blogs {
	@extend .button__white;
	@extend .button_send;
	margin: 0 20px;
	margin-bottom: 75px; // for equal height with connect block

	&:hover {
		color: $white!important;
	}

	@include tablets {
		margin: 0;
		margin-bottom: 26px;
	}
}

a.team__connect_arrow,
.team__connect_arrow {
	display: flex;
	justify-content: center;
	align-items: center;

	&.down {
		@include tablets {
			margin-bottom: 75px;
		}
	}

	&::after {
		 //margin-left: auto;
		 margin-left: 60px; // for IE
		 font-size: 12px;
	}

	// block with links
	& + * {
		min-height: 75px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
}

.team__blogs,
a.team__blogs,
.team__connect,
.team__connect > a { font-size: 16px; }