.video {
	flex: 1 1 30%;

	@include tablets {
		width: 100%;
		margin-bottom: 30px;
	}
}


.video__play {
	position: relative;
	width: 100%;
	height: 100%;
	//max-width: 360px;
	font-size: 0;
	cursor: pointer;

	& > * { font-size: 16px; }

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-repeat: no-repeat;
		background-position: center;
	}

	&::before {
		background-color: rgba($black, .15);
		background-image: url(../img/decor/play_rect.png);
	}

	&::after {
		background-image: url(../img/decor/play_bord.png);
		transform-origin: center;
	}

	&:hover {
		&::after {
			animation-name: spin;
			animation-duration: 1.5s;
			animation-iteration-count: infinite;
			animation-timing-function: ease;
		}
	}
}

