.article-prev {
	flex: 1 1 100%;

	margin: 70px;
	width: calc(100% - 140px);


	@include x-large {
		margin: 70px 30px;
		width: calc(100% - 60px);
	}

	@include medium {
		display: flex;
		flex-wrap: wrap;
		margin: 50px 0;
		width: calc(100% - 0px);

		& > * {
			flex: 1 1 200px;
		}
	}

	@include l-phones {
		& > * {
			flex: 1 1 100%;
		}
	}
}

.article-prev__img {
	height: 260px;
	
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
}

.article-prev__date {
	margin-top: 25px;
	font-family: $os;
	font-size: 16px;
	font-weight: bold;
	line-height: 1;
	color: $grey;

	@include medium   { margin-top: 0; }
	@include l-phones { margin-top: 25px; }
}

.article-prev__title {
	margin-top: 15px;
	margin-bottom: 25px;
	font-family: $os;
	font-weight: 700;
	font-size: 22px;
	text-transform: uppercase;
	color: $black;

	/*height: 1.4em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	//padding-right: 10px;*/
	
	@include medium {
		height: auto;
		overflow: visible;
		white-space: pre-wrap;
		padding-right: 0;
	}
}

/*.article-prev__title_wrap {
	display: block;
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}*/

.article-prev__buttons {
	display: flex;
	align-items: center;
	margin-top: auto;

	& > a[class^=button] {
		width: 49%;

		@include large { font-size: 12px; }
	}

	&.center { justify-content: center; }
	&.right  { justify-content: flex-end; }
	&.between{ justify-content: space-between; }

	@include l-phones {
		margin-top: 25px;
	}
}

.article-prev__text {
	margin-bottom: 25px;

	min-height: 41px; // for Safari

	@include l-phones {
		min-height: auto;
	}
}

.article-prev__content {
	display: flex;
	flex-direction: column;
	height: calc(100% - 260px);
	justify-content: flex-start;

	.teaser__author {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	@include medium {
		height: auto;
		margin-left: 15px;
	}

	@include l-phones {
		margin-left: 0;
	}
}