.banner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	padding-top: 90px;
	padding-bottom: 90px;
	font-size: 20px;
	color: $white;
	background-color: $banner;
	text-align: center;

	margin-bottom: -1px; // for header border

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-image: linear-gradient(to bottom, rgba($black, 0), rgba($black, .4));
	}

	@include phones {
		*[class^=button] {
			max-width: 290px;
			min-width: auto;
			padding: 0;
		}
	}
}

.banner__title {
	font-family: $ci;
	font-style: italic;
	font-size: 70px;
	line-height: 1;
	z-index: 10;

	@include phones { font-size: 32px; }
}

.banner__title_abs {
	@extend .banner__title;

	position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
	bottom: 0;
	padding: 15px;
	
	@include phones {
		font-size: 34px;
	}
}

.banner__text {
	align-self: center; // for IE
	max-width: 950px;
	margin: 0 auto;
	padding: 30px 0 50px;
	padding-bottom: 0;
	z-index: 1;
}

.banner__button {
	z-index: 1;
}


// banner with image as background
.banner_main {
	height: 510px;
	background-position: 50% 50%;
	background-size: cover;
	
	&::before {
		content: none;
	}
}

.banner_nograd {
	&::before {
		content: none;
	}
}

// banner with video as background
.banner_video {
	@extend .banner_main;

	position: relative;
	height: auto;
	max-height: 510px;
	padding: 0;
	overflow: hidden;
	z-index: 1;

	& > video {
		width: 100%;
		height: auto;
	}
}