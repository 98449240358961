.personal {
	display: flex;
	width: 100%;
	max-width: 810px;
	margin: 0 auto;
	padding: 60px 0;
	justify-content: space-between;
	align-items: flex-start;
	text-align: left;

	@include tablets {
		display: block;
		padding: 30px 0;
	}
}

.personal_underline {
	border-bottom: 1px solid $banner;
}

.personal__panel {
	flex: 0 0 190px;

	font-family: $os;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;

	& > * { padding-right: 30px; }

	@include l-phones {
		font-size: 16px;
	}
}

.personal__info {
	flex: 1 1;
}

.personal__list {
	@extend .ul_colordotten;
	margin: 0;
	padding: 0;

	font-family: $os;
	font-size: 24px;

	li::before {
		font-size: 7px;
		margin-right: 30px;
		margin-top: 13px
	}

	@include l-phones {
		font-size: 16px;

		li::before {
			margin-top: 7px;
		}
	}

	@include phones {
		li::before {
			font-size: 5px;
			margin-top: 10px;
			margin-right: 20px;
		}
	}
}