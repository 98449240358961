.connect-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 204px;
	margin: 0 -2px;
	padding: 0;
	list-style: none;

	li {
		//margin: 0;
		flex: 1 1;
		margin: 5px 2px 0;
		padding: 0;
		text-align: center;
	}

	a {
		display: inline-block;
		height: 49px;
		width: 100%;
		max-width: 80px;
		line-height: 47px;
		padding: 0 15px;
		border: 1px solid $banner;
		color: $banner;
		text-decoration: none;
		text-align: center;
		background-position: center;

		&:hover {
			cursor: pointer;
		}

		&.cfp {
			display: block;
			width: 80px;
			font-weight: bold;

			&::before {
				content: "CFP®";
			}
		}

		&.mail {
			&::before { padding: 0; }
		}

		&.cfa {
			width: 100%;
			max-width: 100%;
		}
	}
}

