// Subscribe form in footer
.wpcf7-form.subscribe {
	position: relative;

	// error message
	& > span {
		position: absolute;
		bottom: -24px;
		left: 0;
	}
}

// invalid field 
.wpcf7-not-valid {
	@extend .invalid;
}

.wpcf7-form.subscribe-news__form {
	.wpcf7-response-output.wpcf7-validation-errors {
		border: none;
		margin-left: 0;
		padding-left: 0;
	}
}

form.invalid {
	color: inherit;
}

div.wpcf7-mail-sent-ok,
div.wpcf7-validation-errors {
	border: none!important;
	font-weight: bold;
	text-align: center;
}

div.wpcf7-mail-sent-ok { color: $accent; }
div.wpcf7-validation-errors { color: #c70000; }


// #mc_embed_signup plugin
#mc_embed_signup form {
	display: flex!important;
	align-items: center;
	justify-content: space-between;
}
#mc_embed_signup input {
	border: inherit!important;
    border-color: inherit;
}

// field
#mce-EMAIL {}

// button
#mc-embedded-subscribe {
	width: 160px!important;
	margin: 0 15px 0 0!important;
}

// message

#mce-responses {
	position: absolute!important;
	display: block;
    top: 55px!important;
    left: 0;
    right: 0;
	text-align: center;
	
	margin: 0!important;
    padding: 0!important;
    width: 100%!important;
    font-weight: normal!important;
	line-height: 1.2!important;
	
	a { display: none!important; }
}

.subscribe-news #mce-responses {
	top: auto!important;
	bottom: -40px!important;
}

#mc_embed_signup div.response {
	margin:   0!important;
    padding:  0!important;
	width: 100%!important;
	font-weight: normal!important;
}

// error
.mce_inline_error {
	color: $error-light!important;
}