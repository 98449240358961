// Checklist on the Third-Door page
.i-checklist {
	* { cursor: default; }
}

// Grey blocks with arrows
.i-arrows {
	& > .arrow-block {
		//transform: scale3d(0, 0, 0) rotate3d(0, 0, 0, 0);
		//transform: scale3d(0, 0, 0) translate3d(0, 100%, 0);
		opacity: 0;
		transition: all .8s ease-in-out;

		&.show {
			//transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 360deg);
			//transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
			opacity: 1;
			transition: all .8s ease-in-out;
		}

		&.toTop::after {
			//width: 0px;
			opacity: 0;
			transition: all .5s ease-in-out .5s;
		}

		&.show.toTop::after {
			//width: 38px;
			opacity: 1;
			transition: all .5s ease-in-out .5s;
		}
	}
}

// Cycles on the Home page
.i-cycles {
	.cycle__item {
		opacity: 0;
		transition: all 1.5s ease-out;

		.cycle__title,
		.ul_line {
			opacity: 0;
			transition: all 1.7s ease-out;
		}

		&.show {
			opacity: 1;
			transition: all 1s ease-out;

			.cycle__title {
				opacity: 1;
				transition: all 1s ease-out;
			}

			.ul_line {
				opacity: 1;
				transition: all 1s ease-out .8s;
			}
		}
	}

	& + *[class^="button"] {
		transform: scale3d(0, 0, 0);
		transition: all 1.5s ease-in .5s;
	}

	&.all-show + *[class^="button"] {
		transform: scale3d(1, 1, 1);
		transition: all 1.5s ease-in 1s;
	}
}

// Numbers, Home page
.i-numbers {}

// Circle images (info links) on the Home page
.i-infolinks {

	// background
	&.infolinks {
		background-position: 0 50%;
		background-size: 0% 2px;
		transition: all 4s ease-in-out;

		@include phones {
			background-position: 50% 50px;
			background-size: 100% 0%;
		}
	}

	&_bg.infolinks {
		background-size: 100% 2px;
		transition: all 4s ease-in-out;

		@include phones {
			background-size: 100% 80%;
			transition: all 4s ease-in-out;
		}
	}

	// circles
	.infolinks__item {
		transform: scale3d(0, 0, 0);
		transition: all 1.5s ease-in;

		&.show {
			transform: scale3d(1, 1, 1);
			transition: all 1.5s ease-in;
		}
		
	}

}
