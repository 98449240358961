$padding-top: 15px;

.faq {
	position: relative;
}

.faq__item {
	position: relative;
	margin-bottom: 5px;
	font-size: 0;
	overflow: hidden;

	&:not(:last-child) { margin-bottom: 15px; }

	&.active {
		.faq__punkt {
			& > *:last-child {
				transform: rotate3d(1, 0, 0, 180deg);
				transition: all .5s ease-out;
			}
		}
		.faq__body {
			height: auto;
			
			opacity: 1; // for IE
			transition: opacity .3s ease-in;
		}
	}
}

.faq__title {
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding: 10px 0;
	font-size: 27px;
	font-family: $os;
	font-weight: 800;
	text-transform: uppercase;
	cursor: pointer;

	transition: all .2s ease-in;

	@include tablets { font-size: 3.6vw; }
	@include phones  { font-size: 16px; }
}

.faq__body {
	height: 0;
	padding-bottom: 0;
	padding-top: 0;

	overflow: hidden; // for IE
	opacity: 0; // for IE
	transition: opacity .3s ease-in;

	font-size: 24px;
	@include tablets { font-size: 3vw; }
	@include phones  { font-size: 14px; }

	& > * {
		margin: 50px;
		margin-top: 0;

		@include phones { margin: 0; }
	}

	a {
		@extend .link;
	}
}

// view
.faq__punkt {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;

	& > *:last-child {
		margin-left: 75px;
		transform: rotate3d(1, 0, 0, 0deg);
		transition: all .5s ease-out;

		@include phones { margin-left: 10vw; }
	}

	&:hover {
		& > *:first-child { text-decoration: underline; }
		& > *:last-child  { text-decoration: none; }
	}
}