.section {
	padding-top: $basic-mrg;
	padding-bottom: $basic-mrg;
}

.section_sm {
	padding-top: $small-mgr;
	padding-bottom: $small-mgr;
}

// colors

.section_grey {
	background-color: #fafafa;
}

// limited width
.section__limit_min {
	@include limitWidth(1100px);
}

.section__limit_list {
	@include limitWidth(800px);
}

.section__limit_max {
	@include limitWidth(600px);
}


// columns
.section_twocol {
	@include columns(2);

	@include tablets {
		//flex-direction: column;
		display: block; // for IE
	}
}

.section_twocol-md {
	@include columns(2);

	@include medium {
		//flex-direction: column;
		display: block; // for IE
	}
}