.solution {
	transition: all 1s ease-out;

	height: 0;
	opacity: 0;
	transform: rotate3d(1, 0, 0, 90deg);
	transform-origin: top;

	&.show {
		transition: all 1s ease-out;

		height: 100%;
		opacity: 1;
		transform: rotate3d(1, 0, 0, 0deg);
		transform-origin: top;
	}
}