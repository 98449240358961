// Main image and date/author 
.blog-head {
	display: block;
	width: 90%;
	margin: 0 auto;

	@include large {
		width: 100%;
	}
}

.blog__image {
	display: block;
	//width: 100%;
	//min-height: 300px;
	//max-height: 600px;

	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	img {
		display: block;
		width: 70%;
		margin-left: auto;
		margin-right: auto;

		@include tablets { width: 100%; }
	}

	@include medium {
		//height: 60vw;
	}
}

.blog__info {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;

	@include medium {
		display: block;
	}

	.teaser__author {
		margin-top: 0;
	}

	.teaser__name {
		font-size: 18px;

		@include l-phones { font-size: 14px; }
	}
}

.blog__date {
	font-size: 18px;
	font-weight: bold;
	color: $grey;

	@include l-phones {
		font-size: 0;

		& > * {
			font-size: 14px;
		}
	}
}

// Blog navigation
.blog-nav {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin: 40px 0 50px;
}

.blog-nav__link {
	display: block;
	width: 200px;
	text-decoration: none;
}

.blog-nav__link_next {
	@extend .blog-nav__link;

	margin-left: auto;
	text-align: right;

	.blog-nav__arr {
		padding-right: 70px;
		
		background-image: url(../img/decor/next.png);
		background-position-x: 100%;

		@include phones {
			padding-right: 50px;
		}
	}

	.blog-nav__art {
		flex-direction: row-reverse;
	}
}

.blog-nav__link_prev {
	@extend .blog-nav__link;

	.blog-nav__arr {
		padding-left: 70px;

		background-image: url(../img/decor/prev.png);
		background-position-x: 0;

		@include phones {
			padding-left: 50px;
		}
	}
}

.blog-nav__arr {
	margin: 15px 0;
	font-family: $os;
	font-weight: 700;
	font-size: 14px;
	color: $grey;
	text-transform: uppercase;

	background-repeat: no-repeat;
	background-position-y: center;

	@include phones {
		font-size: 12px;
		background-size: 40px 13px;
	}
}

.blog-nav__art {
	display: flex;
	align-items: center;

	@include phones {
		align-items: flex-start;
	}
}

.blog-nav__pic {
	flex: 0 0 40px;

	display: block;
	width: 40px;
	height: 40px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.blog-nav__title {
	padding: 0 10px;
	font-family: $os;
	font-weight: 600;
	font-size: 14px;
	color: $black;

	@include phones {
		font-size: 12px;
	}
}