$tagsMargin: 100px;

.tags {
	margin-bottom: $tagsMargin;

	@include large {
		margin-bottom: ($tagsMargin - 20px);
	}

	@include tablets {
		margin-bottom: ($tagsMargin / 2);
	}

	&::after {
		content: "";
		display: block;
		width: 75px;
		height: 1px;
		background-color: $black;
		margin: 0 auto;
	}
}

.tags__title {
	font-family: $g;
	font-size: 35px;
	font-weight: bold;
	line-height: 1;
	text-transform: uppercase;
	text-align: center;

	@include tablets {
		font-size: 24px;
	}
}

.tags__cloud {
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	justify-content: space-around;
	margin: $tagsMargin 0;

	@include large {
		margin: ($tagsMargin - 20px) 0;
	}

	@include tablets {
		margin: ($tagsMargin / 2) 0;
	}

	/*&:hover {
		.tags__item {
			color: rgba($black, .5);

			@include tablets {
				color: rgba($black, 1);
			}

			&:hover {
				transform: scale3d(1.2, 1.2, 1.2);
				color: rgba($black, 1);

				@include large {
					transform: none;
				}
			}
		}
	}*/
}

.tags__item {
	display: block;
	font-family: $ci;
	font-size: 37px;
	line-height: 2;
	text-align: center;
	text-decoration: none;
	color: rgba($black, 0);
	
	&.show {
		color: rgba($black, 1);
		animation: kaleidoskop 1.5s ease-out;
	}

	@include large {
		font-size: 3vw;
	}

	@include l-phones {
		font-size: 17px;
		transform: none;
	}
}


@keyframes kaleidoskop {
	0%  { color: rgba($black, 0); }
	25% { color: rgba($banner, 1); }
	50% { color: rgba($accent, 1); }
	100%{ color: rgba($black, 1); }
}