.blog-filter {
	display: block;
	padding: 30px 0;
}

.blog-filter__spoiler {
	display: none;

	@include tablets {
		@include fa($unicode: '\f107');

		display: flex;
		align-items: center;
		font-family: $g;
		font-size: 22px;
		color: $banner;
		text-transform: uppercase;
		

		&::after {
			position: relative;
			display: block;
			margin-left: 7px;
			transform: rotate3d(0, 0, 0, 0);
			transition: all .3s ease-in;
		}

		&.open {
			&::after {
				transform: rotate3d(0, 0, 1, 180deg);
			}
		}
	}
}

.blog-filter__body {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.blog-filter__item {
	flex: 1 1 200px;

	display: flex;
	align-items: center;
	max-width: calc(25% - 10px);
	margin: 5px;
	padding: 10px 12px;
	text-decoration: none;
	background-color: rgba($grey, .2);
	color: $grey;

	cursor: pointer;

	&.active,
	&:hover {
		background-color: rgba($banner, 1);
		color: $white;

		.teaser__name { color: $white; }
		//.teaser__ava { box-shadow: 0 0 0 3px $white; }
	}

	@include large {
		max-width: calc(33% - 10px);
		min-width: 200px;
	}

	@include medium {
		flex: 1 1 50%;
		max-width: calc(50% - 10px);
		min-width: auto;
	}

	@include tablets {
		max-width: calc(50% - 10px);
	}

	@include phones {
		flex: 1 0 100%;
		max-width: 100%;
		min-width: 100%;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}

	& > * {
		margin: 0!important;
	}
}