.page-error {
	margin: 100px 0;

	text-align: center;

	@include l-phones {
		margin-top: 70px;
	}
}

.page-error__code {
	font-family: $os;
	font-weight: 800;
	font-size: 190px;
	line-height: 1;
	
	& > * {
		display: inline-block;
		position: relative;
		color: $accent;
		transform: rotate3d(0, 0, 0, 0);

		transition: all .7s ease-in-out;

		&:hover {
			color: $banner;
			transform: rotate3d(0, 1, 0, 180deg);
		}

		@include l-phones {
			transform: none;
			&:hover { transform: none; }
		}
	}

	@include l-phones {
		font-size: 130px;
		line-height: 1;
	}

}

.page-error__text {
	margin-top: 30px;

	font-family: $os;
	font-size: 50px;
	text-transform: uppercase;

	transition: all .4s ease-in-out;
	
	&:hover {
		color: $accent;
	}

	@include l-phones {
		font-size: 40px;
		line-height: 1;
	}
}