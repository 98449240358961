$bgc: $white;

.modals {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	@include large {
		display: block;
	}
}

.modals__block {
	flex: 0 1 47%;
	padding: 50px 0 110px;
	background-color: $bgc;
	border: 2px solid $accent;

	@include large {
		margin-bottom: 30px;
	}

	&.active {
		position: absolute;
		bottom: auto;
		height: auto;
		top: 5%;
		left: 5%;
		right: 5%;
		box-shadow: 0 0 0 2000px rgba(0,0,0,.8);
		overflow: visible;
		z-index: 100;

		&.modals__block_logo {
			background-image: url(../img/decor/logo-black-m.png);
			background-position: 50% calc(100% - 30px);

			@include tablets {
				background-image: url(../img/decor/logo-black.png);
				background-position: 50% calc(100% - 45px);
			}
		}

		&:hover  { box-shadow: 0 0 0 2000px rgba(0,0,0,.8); }
		// cross
		&::after {
			content: "\f00d";
			font-family: $fa;
			top: 5px;
			right: 10px;
			bottom: auto;
			left: auto;
		}
		// content
		.modals__title {
			margin-bottom: 30px;
			font-size: 40px;

			@include tablets {
				margin: 0 0 50px 0!important;
				font-size: 22px;
			}
		}

		.modals__text {
			//height: calc(100% - 70px);
			height: auto;
			max-height: none;


			line-height: 1.3;
			font-size: 24px;
			text-align: center;
			overflow: auto;
		
			* { margin: 10px 0; }
			p { margin: 1em 0; }

			@include tablets {
				line-height: 1.5rem;
				font-size: 16px;

				p { margin: 0; }
			}
		}

		.over {
			position: static;
			&::after { content: none; }
		}


		// MOBILE
		@include tablets {
			position: static;
			top: 5%;
			bottom: 5%;
			left: 5%;
			right: 5%;
			margin-bottom: 0;
			box-shadow: none;
			overflow: visible;
			z-index: 100;
	
			&:hover  { box-shadow: none; }
			&::after { content: none; }
		}
	}
}

.modals__block_logo {
	background-image: url(../img/decor/logo-black.png);
	background-repeat: no-repeat;
	background-position: 50% calc(100% - 45px);
}

.modals__block_click {
	position: relative;
	margin-bottom: 30px;
	cursor: pointer;

	transition: all .3s ease-out;

	&::after {
		content: "*Click to see full size";
		display: block;
		position: absolute;
		bottom: -25px;
		left: 0;
		right: 0;
		font-size: 13px;
		font-family: $os;
		text-align: center;
	}

	&:hover {
		box-shadow: 0 5px 15px -4px $black;
	}

	@include tablets {
		&::after { content: none; }
		&:hover { box-shadow: none; }
		&:not(:first-child) { margin-top: 25px; }
	}
}

.modals__title {
	margin-bottom: 50px;
	font-size: 22px;
	font-family: $g;
	font-weight: bold;
	text-transform: uppercase;
	color: $accent;
	text-align: center;

	@include large { margin-bottom: 30px; }
}

.modals__text {
	padding: 0 3vw;
	line-height: 1.5rem;
	font-size: 16px;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;

	//p { margin: 0; }

	// for too big blocks, js
	&.over {
		position: relative;
		height: 280px;
		overflow: hidden;

		&::after {
			content: "\f111 \f111 \f111";
			position: absolute;
			display: block;
			bottom: 0;
			left: 2%;
			right: 2%;
			line-height: 3;
			font-family: $fa;
			font-size: 4px;
			letter-spacing: 5px;
			text-align: center;
			background-color: $white;
			//box-shadow: 0 0 15px 0px $white;
			color: $accent;
		}

		@include tablets {
			//position: static;
			height: 200px;

			&::after {
				content: "See full text";
				line-height: 2;
				font-size: 14px;
				font-family: $os;
				white-space: nowrap;
			}
		}
	}
}

.modals__block.active .modals__centered {

	& > *:first-child {
		font-size: 36px;

		@include tablets {
			line-height: 1.5em;
			font-size: 16px;
		}
	}

	@include tablets {
		display: block;
	}
}