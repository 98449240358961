.to-top {
	position: absolute;
	display: block;
	width: 49px;
	height: 49px;
	top: -70px;
	//bottom: 30px;
	//right: 0;
	border: 1px solid $banner;
	background-color: $banner;
	color: $white;
	text-align: center;
	line-height: 43px;
	text-shadow: 1px 1px 3px rgba(0,0,0,.5);
	opacity: .3;
	transition: opacity .3s ease-out;
	cursor: pointer;

	right: calc((100% - 1270px) / 2);

	@include x-large {
		right: 6%;
	}

	@include tablets {
		right: auto;
		left: 15px;
	}

	&:hover {
		opacity: .9;
	}
}