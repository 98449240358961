.blockquote {
	flex: 1 1 70%;
	display: block;
	min-height: 105px;
	margin: 0;
	padding: $small-mgr / 2;
	font-size: 20px;
	background-image: url(../img/decor/bq.png);
	background-position: 0 0;
	background-repeat: no-repeat;

	@include phones {
		height: auto; // for iPhone
		min-height: auto; // for iPhone
		padding: 0;
		padding-top: $small-mgr / 2;
	}
}

.blockquote__signature {
	@extend .blocks_inline;
	flex-wrap: wrap;
	padding-top: $small-mgr / 2;
	font-style: italic;
	font-weight: bold;
}

.blockquote__person {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 300px;
}

.blockquote__img {
	width: 80px;
	height: 80px;
	margin-right: 15px;
	float: left;
}

.blockquote__post,
.blockquote__name {
	display: block;
	line-height: 1;
	padding: 0 10px 9px;
	
	@include phones {
		font-size: 16px;
	}
}

.blockquote__name {
	margin-top: 15px;
}

.blockquote__post {
	color: $banner;

	@include phones { font-size: 16px; }
}

.blockquote__sign {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 250px;
	font-family: $fantasy;
	font-size: 40px;
	text-align: right;

	@include phones {
		flex-basis: 100%;
		padding-right: 15px;
	}
}