.navigator {
	display: flex;
	position: relative;
	width: 100%;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	font-size: 12px;
	color: $black;
}

.navigator__logo {
	flex: 0 0 0;
	order: 1;

	& > .logo {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

.navigator__icons {
	flex: 0 0 80%;
	order: 1;
	padding: 20px 0 0 0;
	font-weight: 600;
}

.navigator__buttons {
	flex: 0 0 10%;
	order: 10;

	*[class^=button] {
		width: 110px;
		padding: 0;
	}
}

.navigator__menu {
	position: relative;
	flex: 0 0 70%;
	order: 1;
	font-family: $os;
	font-weight: 700;
}

.navigator__icons,
.navigator__menu,
.navigator__buttons {
	display: flex;
	justify-content: flex-end;
	
}



@include large {
	.navigator__icons,
	.navigator__buttons,
	.navigator__menu { flex: 0 0 100%; }

	.navigator__logo {
		& > .logo {
			width: 220px;
			top: 45px;
			transform: translateY(0);
		}
	}

	.navigator__buttons { order: 1; }

	.navigator__menu {
		order: 10;
		padding: 0;
	}

	.navigator__icons {
		font-size: 0;

		.nav {
			display: flex;
			align-items: stretch;
			padding: 0;

			li {
				display: flex;
				align-items: center;
			}
		}

		li::before,
		a:before { font-size: 20px; }

		.contact-us,
		.login,
		.tel { font-size: 14px; }

		.mail::before { padding: 0; }

		/*.login {
			&::before {
				display: inline-block;
				padding: 0 7px;
				background: $accent;
				color: $white;
				border-radius: 7px;
				border: 3px solid $accent;
			}

			&:hover {
				&::before {
					color: $accent;
					background: $white;
					border: 3px solid $accent;
				}
			}
		}*/

	}

	.navigator__buttons {
		padding: 20px 0;

		*[class^=button] {
			width: auto;
			padding: 0 15px;
		}

		.dropdown {
			padding-left: 30px;
		}
	}
}


@include tablets {
	.navigator__icons {
		.contact-us { font-size: 0; }

		.tel {
			// font-size: 0;
			display: flex;
			align-items: center;
			
			&::before { margin-top: 4px; }
		}

		.nav_separator > li::before { content: none; }
	}

	.nav_separator {
		width: 240px;
    	align-items: flex-start!important;
		justify-content: space-between;

		li > * { padding: 0; }
		li:first-child { display: none; }
	}
}

@include l-phones {
	.navigator__logo {
		& > .logo {
			width: 40%;
		}
	}
}

@include phones {
	.navigator > * { flex-basis: 100%; }

	.navigator__logo {
		padding-top: 30px;
		& > .logo {
			position: static;
			width: 100%;
			background-position-x: 50%;
		}
	}
	
	.navigator__icons {
		.nav_separator {
			width: auto;

			li:not(:nth-child(2)) { margin-left: 20px; }
		}

		.tel { font-size: 16px; }
	}

	.navigator__buttons .dropdown { padding-left: 20px; }

	.navigator__icons,
	.navigator__buttons {
		justify-content: center;
	}
}