.contacts-slogan {
	@extend .text_center;
	@extend .text_biggaramond;

	margin-bottom: 55px;
}

.contacts-info {
	padding-top: 1px;
	font-size: 24px;
	font-family: $os;
	line-height: 1;
	text-align: center;

	& > * {
		margin: 20px 0;
	}
}

.contacts-form {
	@include limitWidth(700px);
}