.blocks_inline {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.blocks_inline_top {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}