form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  form-section {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  
  form-column {
    display: flex;
    flex: 1 0 25%;
    margin-bottom: 15px;
  }
  
  simple-column {
    margin-bottom: 15px;
  }
  
  form-note {
    font-size: 1.15rem;
    margin-top: 20px;
    margin-left: 5px;
  }
  
  form-footnote {
    display:block;
    font-size: 10px;
  }
  
  .form-side-note {
    padding-left: 15px;
    font-size: 15px;
    margin-top: 10px;
    vertical-align: middle;
  }
  
  .button-gold, .wpcf7-submit {
    background-color: #b18a3e;
    border-color: #b18a3e;
    color: #fff;
    text-shadow: 1px 1px 3px rgba(0,0,0,.5);
    text-transform: uppercase;
    font-family: OpenSans;
    font-weight: bolder;
  }
  
  .button-gold:hover, .wpcf7-submit:hover {
    border-color: #A47D3E;
    background-color: #A47D3E;
  }
  
  .button-gold:focus {
    outline-color: unset;
  }
  
  .button-arrow::before {
    content: "\f107";
    font-family: FontAwesome;
    text-decoration: none;
  }
  
  .active .button-arrow::before {
    content: "\f106"!important;
  }
  
  .tel-162 {
    margin-left: 16px;
  }
  
  .wpcf7-free-text {
    font-family: OpenSans;
    font-weight: bold;
    border: none;
    background: none;
    outline: none;
    border-bottom: 1px solid #999;
  }
  
  .wpcf7-form-control {
    border-width: thin;
    overflow: visible;
    border-color: #999;
    font-size: 1.125rem;
    border-style: solid;
    padding: 12px 15px;
    max-width: 100%;
    font-family: OpenSans;
    font-weight: bold;
   }
  
   ::placeholder {
     color: #999999;
     opacity: 1;
   }
  
  .wpcf7-form label {
    margin: 1.25rem 0;
    font-family: OpenSans;
    font-weight: bold;
    font-size: 1.25rem;
    text-transform: uppercase;
    color: black;
  }
  
  .wpcf7-form-control.wpcf7-checkbox {
    border: none;
    display: table;
  }
  
  .wpcf7-form-control.wpcf7-checkbox .wpcf7-list-item {
    display: table-cell;
    width: 50%;
    float: left;
    margin:0;
    margin-bottom: 12px;
  }
  
  .wpcf7-list-item-label {
    text-transform: initial;
  }
  
  .wpcf7-form-control.wpcf7-checkbox input[type="checkbox"] {
    border: 1px solid;
    content: " ";
    height: 24px;
    width: 24px;
    transform: translateY(-2px);
    vertical-align: middle;
    outline-style: none;
  }
  
  .wpcf7-form-control.wpcf7-checkbox input[type="checkbox"]:checked:before {
    font-family: FontAwesome;
    content: "\f00C";
  }
  
  .gold-form {
    background-color: #b18a3e;
    color: #fff;
  }
  
  .gold-form .wpcf7-form label {
    margin: 1.25rem 0;
    font-family: OpenSans;
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: uppercase;
    color: black;
  }
  
  .center {
    text-align: center;
  }

  .wpcf7-form-control.wpcf7-text.wpcf7-email {
    padding: 12px 15px;
  }
  
  @media (max-width: 600px) {
  
    .wpcf7-form-control.wpcf7-checkbox .wpcf7-list-item {
      width: 100%;
    }
  
    .wpcf7-form-control {
      margin-bottom: 16px;
      margin-top: 10px;
      width: 100%;
    }
  
  }