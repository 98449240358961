.wrapper {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.header,
.footer {
	flex-shrink: 0;
}

.main {
	flex: 1 0 auto;
}