.modalYT {
	display: block;
	z-index: 1;

	img { width: 100%; }
}

.modalYT__popup {
	position: fixed;
	display: block;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	color: white;
	background-color: rgba(black, .5);
	z-index: 1000;

	& > iframe,
	& > video  {
		position: absolute;
		max-width: 100%;
		max-height: 90%;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
	}
}

.modalYT__close {
	position: absolute;
	right: 0;
	padding: 15px 20px;
	text-align: center;
	z-index: 1000;
	cursor: pointer;

	&::after {
		content: "\f00d";
		font-family: $fa;
		font-size: 36px;
	}

	@include tablets {
		padding: 5px 10px;

		&::after { font-size: 24px; }
	}
}