.welcome {
	@extend .section;
	@extend .wrap-content;

	& > .h2 { margin-bottom: $small-mgr; }
}

.welcome__cont {
	@extend .blocks_inline_top;

	@include tablets {
		flex-direction: column-reverse;
	}
}