.icon-circle {
	display: inline-block;
	width: $icon-d;
	height: $icon-d;
	line-height: $icon-d;
	border-radius: 50%;
	background-color: $sub;
	text-align: center;
}

*.icon-circle__social,
a.icon-circle__social {
	@extend .icon-circle;
	font-size: 15px;
	color: $white;
	text-decoration: none;
	transition: all .5s ease-in-out;

	&:hover {
		background-color: $white;
		color: $sub;
		transition: all .5s ease-in-out;
	}
}

// colors for FA-icons
.icon-color_accent {
	&::before,
	&::after { color: $accent; }
}

// socials

.gp { @include fa("\f0d5"); }
.fb { @include fa("\f09a"); }
.tu { @include fa("\f173"); }
.in { @include fa("\f0e1"); }

// contact icons
.mail {
	@include fa("\f003", "before");
	&::before { padding-right: 8px; }

	&:hover { @include fa("\f2b7", "before"); }
}

.tel {
	@include fa("\f095", "before");
	&::before { padding-right: 8px; }

	&:hover { @include fa("\f2a0", "before"); }
}

.login {
	@include fa("\f2c0", "before");
	text-transform: uppercase;
	font-family: $os;
	font-weight: 800;
	&::before { padding-right: 8px; }
}

.map {
	@include fa("\f041", "before");
}

.up {
	@include fa("\f077");
}

.down {
	@include fa("\f078");
}

.tel_white { @include addSprite($y: -$sprite-tel-white-y); }
.tel_green { @include addSprite($y: -$sprite-tel-green-y); }
.fax_white { @include addSprite($y: -$sprite-fax-y); }
.tel_white,
.fax_white { padding-left: 20px; }