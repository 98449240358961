.pagination {
	display: flex;

	// hidden text
	.screen-reader-text,
	.meta-nav.screen-reader-text,
	.next.page-numbers { display: none; }

	// block with links
	.nav-links {
		margin: 0 auto;
		margin-bottom: 70px;
	}

	// number of page
	.page-numbers {
		//display: inline-flex;
		align-items: center;
		justify-content: center;
		//min-width: 50px;
		width: 40px;
		height: 40px;
		//padding: 10px;
		font-family: $os;
		font-weight: 600;
		font-size: 22px;
		color: $black;
		text-decoration: none;
		text-align: center;

		background-color: rgba($accent, 0);
		transition: all .3s ease-in;

		&.dots,
		&.current {
			font-family: $os;
			font-weight: 800;
		}
	}

	a.page-numbers:hover {
		color: $accent;
		background-color: rgba($accent, .2);
	}
}