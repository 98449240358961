// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-c1-name: 'sprite__c1';
$sprite-c1-x: 0px;
$sprite-c1-y: 0px;
$sprite-c1-offset-x: 0px;
$sprite-c1-offset-y: 0px;
$sprite-c1-width: 14px;
$sprite-c1-height: 11px;
$sprite-c1-total-width: 93px;
$sprite-c1-total-height: 11834px;
$sprite-c1-image: 'sprite.png';
$sprite-c1: (0px, 0px, 0px, 0px, 14px, 11px, 93px, 11834px, 'sprite.png', 'sprite__c1', );
$sprite-c2-name: 'sprite__c2';
$sprite-c2-x: 0px;
$sprite-c2-y: 939px;
$sprite-c2-offset-x: 0px;
$sprite-c2-offset-y: -939px;
$sprite-c2-width: 15px;
$sprite-c2-height: 15px;
$sprite-c2-total-width: 93px;
$sprite-c2-total-height: 11834px;
$sprite-c2-image: 'sprite.png';
$sprite-c2: (0px, 939px, 0px, -939px, 15px, 15px, 93px, 11834px, 'sprite.png', 'sprite__c2', );
$sprite-c3-name: 'sprite__c3';
$sprite-c3-x: 0px;
$sprite-c3-y: 311px;
$sprite-c3-offset-x: 0px;
$sprite-c3-offset-y: -311px;
$sprite-c3-width: 14px;
$sprite-c3-height: 14px;
$sprite-c3-total-width: 93px;
$sprite-c3-total-height: 11834px;
$sprite-c3-image: 'sprite.png';
$sprite-c3: (0px, 311px, 0px, -311px, 14px, 14px, 93px, 11834px, 'sprite.png', 'sprite__c3', );
$sprite-fax-name: 'sprite__fax';
$sprite-fax-x: 0px;
$sprite-fax-y: 1254px;
$sprite-fax-offset-x: 0px;
$sprite-fax-offset-y: -1254px;
$sprite-fax-width: 15px;
$sprite-fax-height: 15px;
$sprite-fax-total-width: 93px;
$sprite-fax-total-height: 11834px;
$sprite-fax-image: 'sprite.png';
$sprite-fax: (0px, 1254px, 0px, -1254px, 15px, 15px, 93px, 11834px, 'sprite.png', 'sprite__fax', );
$sprite-gp-name: 'sprite__gp';
$sprite-gp-x: 0px;
$sprite-gp-y: 1569px;
$sprite-gp-offset-x: 0px;
$sprite-gp-offset-y: -1569px;
$sprite-gp-width: 23px;
$sprite-gp-height: 15px;
$sprite-gp-total-width: 93px;
$sprite-gp-total-height: 11834px;
$sprite-gp-image: 'sprite.png';
$sprite-gp: (0px, 1569px, 0px, -1569px, 23px, 15px, 93px, 11834px, 'sprite.png', 'sprite__gp', );
$sprite-i1-name: 'sprite__i1';
$sprite-i1-x: 0px;
$sprite-i1-y: 2959px;
$sprite-i1-offset-x: 0px;
$sprite-i1-offset-y: -2959px;
$sprite-i1-width: 84px;
$sprite-i1-height: 84px;
$sprite-i1-total-width: 93px;
$sprite-i1-total-height: 11834px;
$sprite-i1-image: 'sprite.png';
$sprite-i1: (0px, 2959px, 0px, -2959px, 84px, 84px, 93px, 11834px, 'sprite.png', 'sprite__i1', );
$sprite-i2-name: 'sprite__i2';
$sprite-i2-x: 0px;
$sprite-i2-y: 3343px;
$sprite-i2-offset-x: 0px;
$sprite-i2-offset-y: -3343px;
$sprite-i2-width: 85px;
$sprite-i2-height: 85px;
$sprite-i2-total-width: 93px;
$sprite-i2-total-height: 11834px;
$sprite-i2-image: 'sprite.png';
$sprite-i2: (0px, 3343px, 0px, -3343px, 85px, 85px, 93px, 11834px, 'sprite.png', 'sprite__i2', );
$sprite-i3-name: 'sprite__i3';
$sprite-i3-x: 0px;
$sprite-i3-y: 2200px;
$sprite-i3-offset-x: 0px;
$sprite-i3-offset-y: -2200px;
$sprite-i3-width: 93px;
$sprite-i3-height: 79px;
$sprite-i3-total-width: 93px;
$sprite-i3-total-height: 11834px;
$sprite-i3-image: 'sprite.png';
$sprite-i3: (0px, 2200px, 0px, -2200px, 93px, 79px, 93px, 11834px, 'sprite.png', 'sprite__i3', );
$sprite-i4-name: 'sprite__i4';
$sprite-i4-x: 0px;
$sprite-i4-y: 2579px;
$sprite-i4-offset-x: 0px;
$sprite-i4-offset-y: -2579px;
$sprite-i4-width: 79px;
$sprite-i4-height: 80px;
$sprite-i4-total-width: 93px;
$sprite-i4-total-height: 11834px;
$sprite-i4-image: 'sprite.png';
$sprite-i4: (0px, 2579px, 0px, -2579px, 79px, 80px, 93px, 11834px, 'sprite.png', 'sprite__i4', );
$sprite-sprite-name: 'sprite__sprite';
$sprite-sprite-x: 0px;
$sprite-sprite-y: 6067px;
$sprite-sprite-offset-x: 0px;
$sprite-sprite-offset-y: -6067px;
$sprite-sprite-width: 93px;
$sprite-sprite-height: 5767px;
$sprite-sprite-total-width: 93px;
$sprite-sprite-total-height: 11834px;
$sprite-sprite-image: 'sprite.png';
$sprite-sprite: (0px, 6067px, 0px, -6067px, 93px, 5767px, 93px, 11834px, 'sprite.png', 'sprite__sprite', );
$sprite-tel-green-name: 'sprite__tel-green';
$sprite-tel-green-x: 0px;
$sprite-tel-green-y: 1884px;
$sprite-tel-green-offset-x: 0px;
$sprite-tel-green-offset-y: -1884px;
$sprite-tel-green-width: 16px;
$sprite-tel-green-height: 16px;
$sprite-tel-green-total-width: 93px;
$sprite-tel-green-total-height: 11834px;
$sprite-tel-green-image: 'sprite.png';
$sprite-tel-green: (0px, 1884px, 0px, -1884px, 16px, 16px, 93px, 11834px, 'sprite.png', 'sprite__tel-green', );
$sprite-tel-white-name: 'sprite__tel-white';
$sprite-tel-white-x: 0px;
$sprite-tel-white-y: 625px;
$sprite-tel-white-offset-x: 0px;
$sprite-tel-white-offset-y: -625px;
$sprite-tel-white-width: 14px;
$sprite-tel-white-height: 14px;
$sprite-tel-white-total-width: 93px;
$sprite-tel-white-total-height: 11834px;
$sprite-tel-white-image: 'sprite.png';
$sprite-tel-white: (0px, 625px, 0px, -625px, 14px, 14px, 93px, 11834px, 'sprite.png', 'sprite__tel-white', );
$sprite-v1-name: 'sprite__v1';
$sprite-v1-x: 0px;
$sprite-v1-y: 4506px;
$sprite-v1-offset-x: 0px;
$sprite-v1-offset-y: -4506px;
$sprite-v1-width: 90px;
$sprite-v1-height: 90px;
$sprite-v1-total-width: 93px;
$sprite-v1-total-height: 11834px;
$sprite-v1-image: 'sprite.png';
$sprite-v1: (0px, 4506px, 0px, -4506px, 90px, 90px, 93px, 11834px, 'sprite.png', 'sprite__v1', );
$sprite-v2-name: 'sprite__v2';
$sprite-v2-x: 0px;
$sprite-v2-y: 5676px;
$sprite-v2-offset-x: 0px;
$sprite-v2-offset-y: -5676px;
$sprite-v2-width: 89px;
$sprite-v2-height: 91px;
$sprite-v2-total-width: 93px;
$sprite-v2-total-height: 11834px;
$sprite-v2-image: 'sprite.png';
$sprite-v2: (0px, 5676px, 0px, -5676px, 89px, 91px, 93px, 11834px, 'sprite.png', 'sprite__v2', );
$sprite-v3-name: 'sprite__v3';
$sprite-v3-x: 0px;
$sprite-v3-y: 4896px;
$sprite-v3-offset-x: 0px;
$sprite-v3-offset-y: -4896px;
$sprite-v3-width: 82px;
$sprite-v3-height: 90px;
$sprite-v3-total-width: 93px;
$sprite-v3-total-height: 11834px;
$sprite-v3-image: 'sprite.png';
$sprite-v3: (0px, 4896px, 0px, -4896px, 82px, 90px, 93px, 11834px, 'sprite.png', 'sprite__v3', );
$sprite-v4-name: 'sprite__v4';
$sprite-v4-x: 0px;
$sprite-v4-y: 5286px;
$sprite-v4-offset-x: 0px;
$sprite-v4-offset-y: -5286px;
$sprite-v4-width: 80px;
$sprite-v4-height: 90px;
$sprite-v4-total-width: 93px;
$sprite-v4-total-height: 11834px;
$sprite-v4-image: 'sprite.png';
$sprite-v4: (0px, 5286px, 0px, -5286px, 80px, 90px, 93px, 11834px, 'sprite.png', 'sprite__v4', );
$sprite-v5-name: 'sprite__v5';
$sprite-v5-x: 0px;
$sprite-v5-y: 3728px;
$sprite-v5-offset-x: 0px;
$sprite-v5-offset-y: -3728px;
$sprite-v5-width: 82px;
$sprite-v5-height: 89px;
$sprite-v5-total-width: 93px;
$sprite-v5-total-height: 11834px;
$sprite-v5-image: 'sprite.png';
$sprite-v5: (0px, 3728px, 0px, -3728px, 82px, 89px, 93px, 11834px, 'sprite.png', 'sprite__v5', );
$sprite-v6-name: 'sprite__v6';
$sprite-v6-x: 0px;
$sprite-v6-y: 4117px;
$sprite-v6-offset-x: 0px;
$sprite-v6-offset-y: -4117px;
$sprite-v6-width: 90px;
$sprite-v6-height: 89px;
$sprite-v6-total-width: 93px;
$sprite-v6-total-height: 11834px;
$sprite-v6-image: 'sprite.png';
$sprite-v6: (0px, 4117px, 0px, -4117px, 90px, 89px, 93px, 11834px, 'sprite.png', 'sprite__v6', );
$spritesheet-width: 93px;
$spritesheet-height: 11834px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites: ($sprite-c1, $sprite-c2, $sprite-c3, $sprite-fax, $sprite-gp, $sprite-i1, $sprite-i2, $sprite-i3, $sprite-i4, $sprite-sprite, $sprite-tel-green, $sprite-tel-white, $sprite-v1, $sprite-v2, $sprite-v3, $sprite-v4, $sprite-v5, $sprite-v6, );
$spritesheet: (93px, 11834px, 'sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
