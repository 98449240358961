.input {
	@extend %input;
	
	font-size: 18px;
	border-color: $grey;
}

select.input {
	padding-right: 20px;

	overflow: hidden;
	text-overflow: ellipsis;
}

.input-underline {
	padding: 0 15px;
	font-size: 18px;
	font-family: $os;
	font-weight: 700;
	border: none;
	background: none;
	outline: none;
	border-bottom: 1px solid $grey;
}

.input_transparent {
	background: transparent;

	&:focus { background: $white; }
}

.textarea {
	@extend .input;

	height: 125px;
	resize: vertical;
}

// COMMON

.invalid,
.wpcf7-not-valid {
	border-color: $error;
	color: $error;
}

.input:focus { outline: 3px solid $banner; }

.input::-moz-placeholder          { color: $grey; }
.input::-webkit-input-placeholder { color: $grey; }
.input::-ms-input-placeholder     { color: $grey; }
.input::-ms-input-placeholder     { color: $grey; }
.input::placeholder               { color: $grey; } 

.input:focus::-moz-placeholder          { color: rgba($grey, 0); }
.input:focus::-webkit-input-placeholder { color: rgba($grey, 0); }
.input:focus::-ms-input-placeholder     { color: rgba($grey, 0); }
.input:focus::-ms-input-placeholder     { color: rgba($grey, 0); }
.input:focus::placeholder               { color: rgba($grey, 0); } 

input[type=submit] { cursor: pointer; }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none; 
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
	input,
	select,
	textarea,
	*.input { 
		/* remove inner shadow on iOS input */ 
		//background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(hsla(0,0%,100%,0)), to(hsla(0,0%,100%,0))); 
		//background-image: -webkit-linear-gradient(hsla(0,0%,100%,0), hsla(0,0%,100%,0));
		-webkit-appearance: none;
		border-radius: 0px!important;
	}

	select {
		background-image: url(../img/decor/arrow-down.png);
		background-repeat: no-repeat;
		background-position: 96% 50%;
	}
}
