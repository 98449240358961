$aside: 220px;

.resources {
	display: flex;
	flex-direction: row-reverse;

	@include tablets {
		flex-direction: row;
		flex-wrap: wrap;

		& > * { flex: 1 1 100%; }
	}
}

// CONTENT
.resources__content {
	flex-grow: 1;

	padding-left: 125px;

	@include x-large { padding-left: calc(6% - 30px); }
	@include tablets { padding-left: 6%; order: 2; }
	@include phones { padding-left: 15px; }
}

#resources > .resources__content > * {
	display: none;

	&.active {
		display: block;
	}
}

// ASIDE
.resources__aside {
	flex: 0 0 $aside;

	@include tablets {
		flex: 1 1 100%;
		order: 1;

		display: flex;
		flex-wrap: wrap;
	}
}

.resources__tab {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	width: $aside;
	height: $aside;
	padding: 0 50px;
	padding-top: 100px;
	font-family: $os;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	color: $black;
	background-color: $arblock;

	// background image (JS)
	background-size: 30px 30px;
    background-position: 50% 60px;
	background-repeat: no-repeat;

	transition: all .3s ease-out;

	cursor: pointer;

	@include tablets {
		flex: 0 0 33%;
	}

	@include l-phones {
		flex: 0 0 50%;
		box-shadow: inset 0 0 10px -5px rgba($black, .5);
	}

	@include phones {
		height: 150px;
		padding: 0 15px;
		padding-top: 80px;
		background-position: 50% 40px;
	}

	&:hover { background-color: #e8e8e8; }

	// active tab
	&.active {
		color: $white;
		background-color: rgba($banner, .8);

		&:hover { background-color: rgba($banner, 1); }
	}
}