.text-info {
	font-size: 20px;
	font-family: $os;
	font-weight: 800;
	text-decoration: none;
}

.text-os_bold {
	font-family: $os;
	font-size: $font-basic * 1.11;
	font-weight: bold;
}

.text-note  {
	font-family: $os;
	font-size: 18px;
	font-weight: bold;
	color: $grey;
}

.text-note_ms300 {
	font-family: $os;
	font-size: 14px;
}

.text-note_ms700 {
	font-family: $os;
	font-weight: 700;
	font-size: 14px;
}

.text-smaller {
	font-size: 11px;
	font-family: $os;
	text-decoration: none;
}