.spin-circle {
	display: block;
	width: 150px;
	height: 150px;
	margin: 55px auto 40px;
	border: 4px solid $black;

	transform: rotate3d(0, 1, 0, 180deg); // reverce

	&::before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;

		background-image: url(../img/decor/circle.svg);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		animation: spin-rev 25s cubic-bezier(0.32, 0.33, 0.61, 0.6) infinite;
	}
}


// 
.spin-cycles {
	position: relative;
	display: block;
	width: 300px;
	height: 300px;
	margin-left: auto;
	margin-right: auto;

	border: 3px solid $black;
	border-radius: 50%;

	@include phones {
		width:  270px;
		height: 270px;
	}

	// transition
	&,
	& > * { transition: transform .3s cubic-bezier(0.32, 0.33, 0.61, 0.6); }
}

.spin-cycles__pic {
	position: absolute;
	display: block;
	width: 80px;
	height: 80px;

	background-color: $white;
	border-radius: 50%;

	background-position: center;
	background-size: 60%;
	background-repeat: no-repeat;

	&:nth-of-type(1) {
		top: calc(50% - 40px);
		left: -40px;
	}

	&:nth-of-type(2) {
		top: calc(10% - 40px);
		left: 30px;
	}

	&:nth-of-type(3) {
		top: calc(10% - 40px);
		right: 30px;
	}

	&:nth-of-type(4) {
		top: calc(50% - 40px);
		right: -40px;
	}

	&:nth-of-type(5) {
		top: calc(90% - 40px);
		right: 30px;
	}

	&:nth-of-type(6) {
		top: calc(90% - 40px);
		left: 30px;
	}
}

.c-calendar { background-image: url(../img/decor/cycle/v5.png); }
.c-init     { background-image: url(../img/decor/cycle/v1.png); }
.c-plan     { background-image: url(../img/decor/cycle/v3.png); }
.c-prep     { background-image: url(../img/decor/cycle/v2.png); }
.c-implem   { background-image: url(../img/decor/cycle/v4.png); }
.c-meeting  { background-image: url(../img/decor/cycle/v6.png); }