.logo {
	display: block;
	width: 255px;
	height: 46px;
	background-image: url(../img/decor/logo.png);
	background-size: contain;
    background-repeat: no-repeat;

	&:hover,
	&:focus,
	&:visited,
	&:active {
		text-decoration: none;
		outline: none;
	}
}