.articles {
	display: flex;
	flex-wrap: wrap;
}

.articles__block {
	flex: 0 0 50%;
	max-width: 50%;

	display: flex;
    width: 100%;
    align-items: stretch;
	align-content: stretch;
	
	@include medium {
		flex: 1 1 100%;
		max-width: 100%;
	}
}

// main section
.articles_main {
	// titles
	.article-prev__title {
		flex-grow: 1;
		display: flex;
		align-items: flex-end;
	}
	// no text
	.article-prev__text { display: none; }
}