.most-articles {}

.most-articles__block {
	margin: 40px 0;
}

.most-articles__title {
	font-family: $os;
	font-size: 24px;
	font-weight: bold;
	color: $black;
	text-decoration: none;
}
a.most-articles__title {
	transition: all .3s ease-in;

	&:hover {
		color: $banner;
	}
}

.most-articles__info {
	font-family: $os;
	font-size: 22px;
	color: $black;
	text-decoration: none;
}

.most-articles__date,
.most-articles__section {
	display: inline-block;
	padding: 0 5px;
}