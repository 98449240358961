//var
$size: 24px;
$bg: #fff;
$mrg-check: 0;
$mrg-radio: 6px;
$border: 1px solid $grey;
$brd-radius-check: 0;
$brd-radius-radio: 50%;
$custom-background: #000;   /* color or/and image */

// checkbox and radio
.checkbox,
.radio { display: none; }
 /* view for custom checkbox */
.checkbox-custom,
.radio-custom {
	position: relative;
    width: $size;
    height: $size;
	background: $bg;
    border: $border;
	cursor: pointer;
}

/* centering */
.checkbox-custom,
.radio-custom,
.label {
    display: inline-block;
    vertical-align: middle;
}

/* custom element */
.checkbox:checked + .checkbox-custom::before,
.radio:checked + .radio-custom::before {
    content: "";
    display: block;
    position: absolute;
}

// checkbox
.checkbox:checked + .checkbox-custom { border: 1px solid #000; }
.checkbox:checked + .checkbox-custom::before {
	content: "\f00c";
	font-family: $fa;
	font-size: 14px;
	text-align: center;

	animation: check .7s ease-in-out;
}
.checkbox-custom,
.checkbox:checked + .checkbox-custom::before { border-radius: $brd-radius-check; }

.checkbox:checked + .checkbox-custom::before {
	/* positioning */
    top: $mrg-check;
    right: $mrg-check;
    bottom: $mrg-check;
	left: $mrg-check;
	line-height: 1.5;
		/* view */
    //background: $custom-background;
}



// radio
.radio-custom,
.radio:checked + .radio-custom::before {
	border-radius: $brd-radius-radio;
	
	animation: check .7s ease-in-out;
}

.radio:checked + .radio-custom::before {
		/* positioning */
    top: $mrg-radio;
    right: $mrg-radio;
    bottom: $mrg-radio;
    left: $mrg-radio;
		/* view */
    background: $custom-background;
}



//text in label
.label {
	padding-left: 10px;
	color: $grey;
	font-family: $os;
	font-weight: 700;
	font-size: 16px;
}
.radio:checked ~ .label { color: $custom-background; }
.checkbox:checked ~ .label { color: $custom-background; }


// Flex

.flex-label {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	line-height: 1;

	& > .checkbox-custom,
	& > .radio-custom { flex-shrink: 0; }
}