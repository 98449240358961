.subscribe-news {
	display: block;
	width: 100%;
	padding: 70px 90px;

	.h_topline { margin-top: 0; }

	@include x-large {
		padding-left: 70px;
		padding-right: 70px;
	}

	@include medium {
		padding: 50px;
		padding-left: 30px;
		padding-right: 30px;
	}

	@include phones {
		padding: 30px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.subscribe-news_white {
	color: $black;
	background-color: $white;
	border: 1px solid;

	.button_send {
		@extend .button__white;
	}
}

.subscribe-news_color {
	color: $white;
	background-color: $banner;
	//border: 1px solid;

	.h_topline::before { background-color: $white; }

	.input {
		background-color: $banner;
		border-color: $white;
		color: $white;
	}
	.input::-moz-placeholder          { color: $white; }
	.input::-webkit-input-placeholder { color: $white; }
	.input::-ms-input-placeholder     { color: $white; }
	.input::-ms-input-placeholder     { color: $white; }
	.input::placeholder               { color: $white; } 
	.input:focus::-moz-placeholder          { color: rgba($white, 0); }
	.input:focus::-webkit-input-placeholder { color: rgba($white, 0); }
	.input:focus::-ms-input-placeholder     { color: rgba($white, 0); }
	.input:focus::-ms-input-placeholder     { color: rgba($white, 0); }
	.input:focus::placeholder               { color: rgba($white, 0); } 

	.input:focus { outline: 3px solid rgba($white, .5); }

	.checkbox-custom::before { color: $banner; }
	.checkbox-custom {
		background: $banner;
		border: 1px solid $white;
	}
	.checkbox:checked+.checkbox-custom {
		background: $white;
		border: 1px solid $white;
	}
	.label { color: rgba($white, .5); }
	.checkbox:checked ~ .label { color: rgba($white, 1); }
	
	.button_send {
		@extend .button__light;

		&:hover {
			background-color: $white;
			color: $banner;
			text-shadow: none;
		}
	}
}

// Form

.subscribe-news__form {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	max-width: 100%;
	margin-top: 50px;

	* { max-width: 100%; }

	.h_topline {
		margin-top: 0;
	}
}

.subscribe-news__col {
	flex: 0 0 50%;

	& > * {
		display: block;
		width: 100%;
	}

	.input {
		margin-bottom: 15px;
	}

	span.invalid {
		margin-bottom: 20px;
	}

	@include l-phones {
		flex: 1 1 100%;
	}

}

.subscribe-news__checks {
	margin-left: 45px;

	.h5 {
		margin-bottom: 25px;
	}

	@include medium {
		margin-left: 20px;
	}

	@include l-phones {
		margin-left: 0;
	}
}

.subscribe-news__send {
	flex: 1 1 100%;
	margin-top: 30px;
	text-align: center;
}

// for WP plugin
.subscribe-news__col .input,
.subscribe-news__col .textarea,
.subscribe-news__send .button_send {
	box-shadow: 0 0 0px 1px;
}