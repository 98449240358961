.content {
	width: 100%;

	p {
		margin: 18px 0;
		line-height: 1.5;
		font-size: 21px;
		font-family: $os;
	
		@include phones {
			margin: 10px 0;
			font-size: 16px;
		}
	}

	.p {
		margin: 0 0 18px 0;
		line-height: 1.5;
		font-family: $os;
	
		@include phones {
			margin: 10px 0;
		}
	}

	img {
		max-width: 100%;
		height: auto!important;
	}
	
	h1, h2, h3, h4, h5, h6 {
		margin: .5em 0;
		line-height: 1.2;
		font-weight: bold;
	}

	h1 {
		font-family: $g;
		font-size: 40px;
		line-height: 1;
		text-transform: uppercase;
	
		@include phones { font-size: 30px; }
	}
	
	h2 {
		font-family: $os;
		font-size: 30px;
		text-transform: uppercase;
	
		@include phones { font-size: 20px; }
	}

	h3 {
		font-family: $os;
		font-size: 30px;
		
		@include phones { font-size: 20px; }
	}

	h4 {
		font-family: $os;
		font-size: 30px;
		font-weight: normal;
	
		@include phones { font-size: 20px; }
	}

	h5, h6 {
		margin: 1rem 0;
	}

	a,
	a:active {
		color: $banner;
		text-decoration: underline;
	}

	a:hover { color: $accent; }
	a:visited { color: $grey; }

	ul, ol {
		@include phones {
			padding-left: 15px;
		}

		li {
			margin: 5px 0;
			font-size: 18px;
			line-height: 1.5;

			@include phones {
				font-size: 14px;
			}
		}
	}

	figure {
		margin: 60px 0;
		text-align: center;

		@include l-phones {
			margin: 30px 0; 
		}
	}
	figcaption {
		font-size: 18px;
		font-weight: bold;
		color: $grey;
	}
}

.content__p {
	margin: 18px 0;
	line-height: 1.5;
	font-size: 21px;
	font-family: $os;

	@include phones {
		margin: 10px 0;
		font-size: 16px;
	}
}

.content__mess {
	@extend .content__p;
	font-style: italic;
}

.content__big {
	@extend .content__p;
	font-size: 30px;

	@include phones { font-size: 20px; }
}

.content__img_fl {
	display: block;
    float: left;
	margin-right: 30px;
	
	@include phones {
		float: none;
		width: 100%;
		margin-right: 0;
	}
}

.content__video_small {
	max-width: 420px;
	width: 50%;

	& > .modalYT {
		min-height: 150px;
	}

	@include medium {
		max-width: 100%;
		width: 100%;
		float: none;
		margin-right: 0;

		margin-bottom: 40px;
	}
}

.content__video_small-md {
	max-width: 420px;
	width: 50%;

	& > .modalYT {
		min-height: 150px;
	}

	@include medium {
		max-width: 50%;
	}

	@include l-phones {
		max-width: 100%;
		width: 100%;
		float: none;
		margin-right: 0;

		margin-bottom: 40px;
	}
}

.content__signature {
	@extend .blockquote__signature;
	width: 100%;
	margin: 50px 0;
	white-space: nowrap;

	.blockquote__person {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 390px;
	}

	@include phones {
		margin: 30px 0;

		.blockquote__person {
			flex-basis: 100%;
			white-space: pre-wrap;
		}
	}
}
.content__signature_right {
	@extend .content__signature;

	justify-content: flex-end;
}

// Content block on the Team-inner page
.content_person {
	p {
		margin: 18px 0;
		line-height: 1.5;
		font-size: 21px;
		font-family: $os;
	
		@include phones {
			margin: 10px 0;
			font-size: 16px;
		}
	}

	img { max-width: 100%; }

	h1, h2, h3 {
		margin: .5em 0;
		line-height: 1.2;
		font-weight: bold;
	}

	h1 {
		font-family: $g;
		font-size: 40px;
	
		@include phones { font-size: 30px; }
	}

	h2 {
		font-family: $os;
		font-size: 30px;
		text-transform: uppercase;
	
		@include phones { font-size: 20px; }
	}

	h3 {
		font-family: $os;
		font-size: 30px;
	
		@include phones { font-size: 20px; }
	}
}

// WP
// images
.aligncenter {
	display: block;
    margin: 1em auto;
}

.alignleft {
	margin: 0.5em;
    margin-right: 1em;
    margin-left: 0;
    float: left;
}

.alignright {
	margin: 0.5em;
    margin-right: 0;
    margin-left: 1em;
    float: right;
}