.map-sheme {
	position: relative;
	display: block;
	margin-bottom: 60px;
	padding: 75px 0 60px;

	#map-usa {
		margin-left: -100px;

		@include l-phones { margin-left: -50px; }
	}

	@include medium {
		padding-bottom: 80px;
		margin-bottom: 30px;
	}

	@include phones {
		padding-top: 30px;
	}
}

.map-sheme__img {
	display: block;
	max-width: 100%;
	margin: auto;
}

.map-sheme__legend {
	position: absolute;
	display: block;
	width: 220px;
	margin: 0;
	padding: 0;
	bottom: 0;
	right: 0;
	font-family: $os;
	font-weight: 800;
	font-size: 20px;

	@include medium {
		bottom: 20px;
	}

	list-style: none;
	li {
		display: flex;
		align-items: center;

		@include fa(
			$unicode: "\f04d", 
			$psevdo: "before"
		);
		&::before {
			font-size: 35px;
			margin-right: 25px;
		}
	}

	.active {
		&::before { color: $accent; }
	}
}