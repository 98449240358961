.cycle {
	@extend .section;
	@extend .section_grey;
	@extend .wrap-content;

	text-align: center; // for link to bottom
}

.cycle__cont {
	display: flex;
	max-width: 810px;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 60px;

	& > *:nth-child(even) {
		&::before {
			content: "";
			display: block;
			height: 300px;

			@include tablets {
				content: none;
			}
		}
	}

	// centered line
	background-image: linear-gradient(90deg, $grey 3px, transparent 0px);
	background-position-x: 50%;
	background-size: 3px 100%; // JS
	background-repeat: no-repeat;

	@include medium {
		max-width: 660px;
	}

	@include tablets {
		display: block;
		background-image: linear-gradient(to bottom,#fff 0,#acacac 10%,#999 90%,#fff 100%);
	}

}

.cycle__col {
	flex: 0 0 50%;

	font-weight: bold;
	text-align: left;

	&:first-of-type {
		text-align: left;

		.cycle__title {
			flex-direction: row-reverse;

			& > span {
				padding-right: 10px;
			}

			// circle
			&::after {
				left: auto;
				right: -17px;

				box-shadow: -15px 0px 0px 0px #fafafa;
			}
		}

		.ul_line {
			margin-right: 26px;
		}

		@include tablets {
			display: none;
		}
	}

	&:nth-of-type(2) {
		text-align: right;

		.cycle__title {
			flex-direction: row;

			& > span {
				padding-left: 10px;
			}

			// circle
			&::after {
				left: -17px;
				right: auto;

				box-shadow: 15px 0px 0px 0px #fafafa;
			}
		}

		.ul_line {
			margin-left: 10px;
		}

		.cycle__item { background-position-x: 100%!important; }

		@include tablets {
			display: none;
		}
	}

	&.cycle__mobile {
		display: none;

		@include tablets {
			display: block;
			text-align: center;
			
			.cycle__title {
				display: block;
	
				& > span {
					padding-left: 0;
					padding-right: 0;
				}
			}

			.ul_line {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}

.cycle__item {
	display: block;
	padding-top: 200px;

	@include tablets {
		height: auto;
		padding-top: 120px;

		background-color: $arblock;

		&:not(:last-of-type) { margin-bottom: 50px; }
	}

}

.cycle__title {
	@extend .h3;
	display: flex;
	position: relative;
	padding: 20px 0;

	text-align: inherit;

	& > span {
		flex-grow: 0;
	}

	// line
	&::before {
		content: "";
		display: block;
		height: 2px;
		flex: 1 0 30%;
		margin: auto 0;
		margin-top: 19px;
		background: $grey;
	}

	// circle
	&::after {
		content: "";
		display: inline-block;
		position: absolute;
		top: 22px;
		width: 30px;
		height: 30px;
		border: 2px solid $banner;
		border-radius: 50%;
		background: $white;
		z-index: 1;
	}

	@include tablets {
		&::before,
		&::after { content: none; }
	}
}

// backgrounds

.cycle__item_v1 { @include bg-icon("../img/decor/cycle/v1.png", $y: 100px); }
.cycle__item_v3 { @include bg-icon("../img/decor/cycle/v3.png", $y: 100px); }
.cycle__item_v5 { @include bg-icon("../img/decor/cycle/v5.png", $y: 100px); }

.cycle__item_v2 { @include bg-icon("../img/decor/cycle/v2.png", $y: 100px); }
.cycle__item_v4 { @include bg-icon("../img/decor/cycle/v4.png", $y: 100px); }
.cycle__item_v6 { @include bg-icon("../img/decor/cycle/v6.png", $y: 100px); }

@include tablets {
	.cycle__item_v1,
	.cycle__item_v2,
	.cycle__item_v3,
	.cycle__item_v4,
	.cycle__item_v5,
	.cycle__item_v6 { background-position: 50% 35px; }
}
