// text before block
.arrow-block-desc {
	display: block;
	max-width: 680px;
	margin: auto;
	padding: 0;
	text-align: justify;

	@include tablets { text-align: left; }
}

// block
.arrow-block {
	position: relative;
	display: block;
	max-width: 680px;
	margin: auto;
	margin-top: 20px;
	margin-bottom: 72px;
	padding: 30px 50px;
	background-color: $arblock;
	text-align: center;

	@include phones { padding: 30px 20px; }

	&.toTop {
		&::after {
			content: "";
			position: absolute;
			display: block;
			width: 38px;
			height: 52px;
			bottom: -52px;
			left: 50%;
			transform: translate3d(-50%, 0, 0);

			background-image: url(../img/decor/arrow.png);
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	h3 {
		font-family: $os;
		font-weight: 700;
		font-size: 20px;
		text-transform: uppercase;
	}

	p {
		font-size: 18px;
	}

	img {
		max-width: 100%; 
	}
}

.arrow-block_full {
	@extend .arrow-block;
	width: 100%;
	max-width: none;
}

.arrow-block__pics {
	padding-top: 55px + 95px + 30px;
	padding-bottom: 55px;
	background-repeat: no-repeat;
	background-position: 50% 55px;
	background-size: auto 95px;

	&:last-of-type { margin-bottom: 0; }
}

.arrow-block__cont {
	padding-top: 30px;
	padding-bottom: 50px;
}