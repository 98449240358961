.personal-info {
	display: block;
	width: 100%;
	max-width: 440px;
	margin-right: 4vw;
	align-self: flex-start;

	background-color: $arblock;

	@include x-large {
		max-width: 325px;
	}

	@include medium {
		max-width: 100%;
		margin-right: 0;
		margin-bottom: 50px;
	}
}

.personal-info__cont {
	width: 100%;
	padding: 40px 0;

	& > *,
	.personal-info__section > * {
		padding-left: 55px;
		padding-right: 55px;

		@include x-large {
			padding-left: 30px;
			padding-right: 30px;
		}
	
		@include medium {
			padding-left: 8vw;
			padding-right: 8vw;
		}
	}

	@include x-large {
		padding: 40px 0;
	}

	@include medium {
		padding: 40px 0;
	}
}

.personal-info__name {
	margin-bottom: 10px;
	font-size: 23px;
	font-family: $g;
	font-weight: bold;
	line-height: 1;
	text-transform: uppercase;

	@include tablets {
		font-size: 30px;
		text-align: center;
	}

	@include phones {
		font-size: 6.5vw;
	}
}

.personal-info__office {
	margin-bottom: 25px;
	margin-top: 0;
	font-size: 20px;
	font-weight: bold;
	line-height: 1;

	@include tablets {
		text-align: center;
		font-style: italic;
		margin-bottom: 40px!important;
	}

	@include phones {
		font-size: 5.5vw;
	}
}

.personal-info__contacts {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	align-items: center;

	li { margin-right: 7px; }

	@include tablets {
		justify-content: flex-start;
	}
}

.personal-info__text {
	padding: 0;
}

.personal-info__section {
	margin: 20px 0;

	.personal-info-section-name {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 5px 0;
		font-family: $os;
		font-size: 18px;
		font-weight: bold;
		lighting-color: 1.5;
		color: $black;
		cursor: pointer;

		transition: all .3s ease-out;

		@include fa("\f078");
		&::after {
			margin-left: auto;
			padding-left: 10px;
			font-size: 12px;
			color: $banner;
		}

		&:hover { color: $banner; }

		&.active {
			& + * {
				height: auto;
				opacity: 1;
				transform: scale3d(1, 1, 1);
			}

			&::after {
				content: "\f077";
			}
		}
	}

	.personal-info-section-name + * {
		height: 0;
		overflow: hidden;
		opacity: 0;
		transform: scale3d(0, 0, 0);

		transform-origin: top;

		transition: all .3s ease-out;
	}
}