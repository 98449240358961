// HELPER CLASSES
// classes with a single purpose to reduce the frequency of highly repetitive declarations

// text
.text_center { text-align: center; }
.text_right  { text-align: right; }
.text_left   { text-align: left; }

.text_bold   { font-weight: bold; }
.text_italic { font-style: italic; }

.text_upper { text-transform: uppercase; }
.text_noupper { text-transform: none; }

.text_biggaramond {
	font-family: $g;
	font-size: 40px;
	font-weight: bold;
	line-height: 1;

	@include phones { font-size: 30px; }
}

.text_big {
	font-family: $os;
	font-size: 24px;

	@include l-phones { font-size: 18px; }
}

.text_nowrap { white-space: nowrap; }

.p {
	margin: 1rem 0;
}

// images
.img_block {
	display: block;
	width: 100%;
}

// margins and paddings
.mrg-no-coll {
	padding-bottom: 1px;
	padding-top: 1px;
}

.img_space {
	margin: 56px 0 40px;
}

.mr { margin-right: 30px;  }
.mb { margin-bottom: 30px; }
.mt { margin-top: 30px!important; }

.mv { // for Team Inner page, first paragraph, video and text on the one line
	margin-top: -7px!important;
}

.mt_section { margin-top: 80px; }
.mb_section { margin-bottom: 80px; }

.pt_section { padding-top: 80px; }
.pb_section { padding-bottom: 80px; }

.pt_sm { padding-top: 3px; }

// display
.none { display: none!important; }
.block{ display: block!important; }

.rel { position: relative; }

// other
.hr {
	display: block;
	width: 33vw;
	min-width: 200px;
	height: 2px;
	margin: 0 auto;
	background-color: $banner;

	clear: both;
}

.hr__narr { height: 1px; }

.hr__black { background-color: $black; }

.hr__bott { margin-bottom: 70px; }

.hr__padd {
	padding-top: 70px;
	padding-bottom: 70px;
	background: none;

	&::before {
		content: "";
		display: block;
		height: 2px;
		background-color: $banner;
	}
}

// WP
.wpcf7-validation-errors {
	margin-left: 0;
	padding-left: 0;
	border: none;
}