.header {
	
}

//block with logo
.header__logo {
	margin: 38px 0;

	@include large {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0;
	}
}

// decor
.header_underline {
	border-bottom: 1px solid $grey;
}