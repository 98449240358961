$padd: 25px;

.nav {
	@extend .blocks_inline;

	li {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	a {
		color: $black;
		text-decoration: none;

		&:hover {
			text-decoration: none;
			color: $banner;
		}
	}
}

.nav_separator {
	& > *:not(:first-child) {
		&::before {
			content: "|";
			padding-left: $padd;
		}

		&:hover {
			&::before {
				color: inherit;
			}
		}
	}

	a {
		@extend %Rubik;
		font-size: 14px;

		padding-right: 0;
		padding-left: $padd;

		@include large { font-size: 0; }

		&:hover { color: $accent; }
	}
}

.nav__menu {
	width: 95%;
	justify-content: space-around;
	margin: 0;
	padding: 0;
	font-family: $os;
	font-weight: 800;
	text-transform: uppercase;
	word-spacing: 4px;

	& > * { position: relative; }

	li {
		display: flex;
		flex-direction: column-reverse;
		justify-content: center;
		padding: 20px 0;
		text-align: center;

		transform-style: preserve-3d;
		perspective: 200;
		z-index: 20;
		transition: all .3s ease-out;

		@include tablets { padding: 5px 0; }

		@include phones {
			display: flex;
			display: -webkit-box;

			ul  {
				margin-top: 0!important;
				& > li:first-child { margin-top: 10px; }
			}
		}
	}

	a {
		@extend %Rubik;
		font-size: 14px;
		padding: 0 12px;

		@include medium { font-size: 13px; }

		&:hover { color: $banner; }
	}

	& > *:hover {
		.nav__submenu {
			display: block;
			margin-top: 3px;

			opacity: 1;
			transform: 	translate3d(-50%, 0, 0)
						rotate3d(1, 0, 0, 0deg);
			transition: all .3s ease-out;

			@include tablets {
				height: 100%;
				padding: 0 0 5px 0;
				opacity: .9;

				transform: none;
			}
		}

		.nav__submenu + a {
			color: $black;

			&::after {
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate3d(-50%, 0, 0);
				height: 4px;
				width: 20px;
				background: $banner;
				transition: all .5s ease-in-out;
				@include tablets { width: 40px; }
			}
		}
	}

	.nav__submenu + a {
		@include tablets {
			padding-bottom: 10px;
			&::after {
				content: "";
				display: block;
				position: absolute;
				bottom: auto;
				top: 27px;
				left: 50%;
				transform: translate3d(-50%, 0, 0);
				height: 4px;
				width: 40px;
				background: $banner;
			}
		}
	}
}
// submenu
.nav__submenu {
	//display: none;
	position: absolute;
	width: 300px;
	padding: 15px;
	top: 58px;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
	border: 1px solid rgba($grey, .5);
	background: $white;
	text-align: center;
	z-index: 100;

	opacity: 0;
	transform: 	translate3d(-50%, 0, 0)
				rotate3d(1, 0, 0, -83deg);
	transform-origin: top;
	
	transition: all .3s ease-out;

	@include tablets {
		display: block;
		position: static;
		height: 100%;
		padding: 0 0 5px 0;
		opacity: .9;
		font-family: $os;
		font-weight: 700;
		font-style: italic;
		font-weight: normal;
		border: 0;
		transform: none;
		opacity: 1;
	}

	@include phones {
		height: auto; // for iPhone
		width: 100%;
	}

	li, a {
		padding: 0;
		word-wrap: normal;
	}

	a {
		line-height: 2;
		text-transform: none;
	}
}


.nav-icons {
	& > * { margin-right: 15px; }

	@include tablets {
		text-align: center;
		& > * { margin: 0 8px; }
	}
}

.nav-col {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	& > * {
		flex: 1 1 50%;

		// for footer
		&:nth-of-type(1) { order: 10; }
		&:nth-of-type(2) { order: 30; }
		&:nth-of-type(3) { order: 50; }
		&:nth-of-type(4) { order: 70; }

		&:nth-of-type(5) { order: 20; }
		&:nth-of-type(6) { order: 40; }
		&:nth-of-type(7) { order: 60; }
		&:nth-of-type(8) { order: 80; }

		&:nth-of-type(5),
		&:nth-of-type(6),
		&:nth-of-type(7) {
			&::before {
				content: "";
				display: inline-block;
				width: 17px;
			}
		}

		& > * { padding-left: 17px; }
	}

	& > *:not([class^=button]) {
		line-height: 1.1;
		padding: 5px 0;
	}

	a[class^=button] {
		margin-left: 15px;
		padding: 0;

		
	}
}