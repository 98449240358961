.ul {
	margin: 0;
	padding: 0;
	line-height: 1.5;
	list-style: none;

	li { padding-bottom: 10px; }
}

.ul_line {
	@extend .ul;
	padding-left: 15px;

	li::before {
		content: "– ";
		margin-left: -14px;
	}
}

.ul_colordotten {
	list-style: none;

	@include phones { padding: 0; }

	li {
		display: flex;
		padding: 5px 0;
		//align-items: center;
		
		@include fa("\f111", "before");
		&::before {
			color: $banner;
			font-size: 10px;
			margin-right: 20px;
			margin-top: 7px;
		}
	}
}

// for ul in the '.arrow-block'
.ul_centered {
	display: flex;
    flex-direction: column;
	align-items: flex-start;
	width: 50%;
	margin-left: auto;
    margin-right: auto;
	padding: 0;
	
	@include tablets { min-width: 300px; }
}

