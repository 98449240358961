// MIXINS
%input {
	display: inline-block;
	width: 280px;
	min-height: $input;
	line-height: $input - 2px;
	padding: 12px 15px;
	font-family: $os;
	font-weight: 700;
	text-decoration: none;
	border: 1px solid;

	@media (max-width: 480px) { width: 100%; }
}

%fa {
	font-family: $fa;
}


// sprite PNG
@mixin addSprite($x: 0, $y: 0) {
	background-image: url(../img/sprites/sprite.png);
	background-repeat: no-repeat;
	background-position-x: $x;
	background-position-y: $y;
}

// icon FontAwesome
@mixin fa($unicode, $psevdo: "after") {
	@if $psevdo == "after" {
		&::after {
			content: $unicode;
			font-family: $fa;
			text-decoration: none;
		}
	}

	@if $psevdo == "before" {
		&::before {
			content: $unicode;
			font-family: $fa;
		}
	}
}

// flex columns
@mixin columns($num) {
	display: flex;
	width: 100%;

	& > * {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: 100% / $num;
	}
}

// background wint icon
@mixin bg-icon($url, $x: 0, $y: 0, $size: auto) {
	background-image: url($url);
	background-repeat: no-repeat;
	background-position-x: $x;
	background-position-y: $y;
	background-size: $size;
}

// vertical line-gradient
@mixin line-gradient(
	$color,
	$deg,
	$lineHeight,
	$position,
	$size
) {
	background-image: linear-gradient($deg, $color $lineHeight, transparent 0px);
	background-position: $position;
	background-size: $size;
	background-repeat: no-repeat;
}

// limited block (width)
@mixin limitWidth($w) {
	max-width: $w;
	margin-left: auto;
	margin-right: auto;
	padding-left: 15px;
	padding-right: 15px;
}

// Animate
@keyframes spin {
    from { transform: rotate(0deg); }
    to   { transform: rotate(360deg); }
}

@keyframes spin-rev {
    from { transform: rotate(0deg); }
    to   { transform: rotate(-360deg); }
}

@keyframes check {
	0%   { transform: scale(0); }
	70%  { transform: scale(2); }
	100% { transform: scale(1); }
}