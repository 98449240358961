.map {
	max-width: 100%;
	width: 100%;
	height: auto;
	
	@include phones {
		height: 70vw; // for iPhone
	}
}

.map__path {
	fill: $grey;
    stroke: $white;
    stroke-width: 1.5;
	stroke-linejoin: round;
	
	&.active {
		fill: $accent;
	}
}