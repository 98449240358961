.subscribe {
	position: relative;
	display: flex;
	max-width: 440px;
	justify-content: flex-end;
	margin-left: auto;
	height: $icon-d;
	background-color: $sub;

	@include tablets {
		margin: auto;
	}

	// WP plugin
	.wpcf7-validation-errors,
	.wpcf7-mail-sent-ok {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin: 0;
		padding-top: 10px;
		text-align: center;
		line-height: 1;
		border: none;
	}

	.wpcf7-mail-sent-ok { color: #398f14; }
}

.subscribe__input {
	width: 100%;
	padding: 0 15px;
	font-size: 16px;
	font-family: $os;
	font-weight: 600;
	color: $white;
	border: none;
	box-shadow: none;
	background: none;
	outline: none;

	overflow: hidden;
    text-overflow: ellipsis;

	@include medium {
		padding: 0 7px;
		font-size: 13px;
	}

	&::-moz-placeholder          { color: $white; }
	&::-webkit-input-placeholder { color: $white; }
	&::-ms-input-placeholder     { color: $white; }
	&::-ms-input-placeholder     { color: $white; }
	&::placeholder               { color: $white; } 
	
	&:focus::-moz-placeholder          { color: rgba($grey, 0); }
	&:focus::-webkit-input-placeholder { color: rgba($grey, 0); }
	&:focus::-ms-input-placeholder     { color: rgba($grey, 0); }
	&:focus::-ms-input-placeholder     { color: rgba($grey, 0); }
	&:focus::placeholder               { color: rgba($grey, 0); } 
}

.subscribe__button {
	margin: 7px 9px;

	@include phones {
		width: 150px;
		margin-left: 0;
		padding: 0;
	}
}

.subscribe__title {
	position: absolute;
	display: block;
	width: 100%;
	top: -25px;
	left: 0;
	right: 0;
	line-height: 1;
	font-size: 18px;
	text-align: center;
	color: $white;
}