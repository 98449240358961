.timeline {
	& > .timeline__card:nth-child(even) {
		flex-direction: row-reverse;

		.timeline__info,
		.timeline__title { text-align: left;}

		.timeline__title::after {
			left: -17px;
			right: auto;
		}

		.timeline__pic::before {
			left: 50%;
			right: 0;
		}

		@include medium {
			.timeline__info,
			.timeline__title { text-align: right; }

			.timeline__pic {
				img {
					margin-left: auto;
					margin-right: auto;
				}

				&::before {
					left: 100px;
					right: -50vw;
				}
			}
		}

		@include tablets {
			.timeline__pic {
				left: 0;
				right: auto;
			}
		}

		@include l-phones {
			flex-direction: column-reverse;

			.timeline__info,
			.timeline__title { text-align: center; }
		}
	}
}

.timeline__card {
	display: flex;
	position: relative;

	// centered line
	background-image: linear-gradient(90deg, $grey 3px, transparent 0px);
	background-position-x: 50%;
	background-size: 3px 100%;
	background-repeat: no-repeat;

	// Year
	&::before {
		content: attr(data-year);
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		font-family: $g;
		font-size: 350px;
		font-weight: bold;
		line-height: 1;
		color: rgba($banner, .2);

		z-index: -1;

		@include medium {
			align-items: flex-end;
			font-size: 35vw;

			top: 170px;
			bottom: auto;
		}

		@include l-phones {
			content: none;
		}
	}

	// light grey line to bottom
	&:last-of-type {
		&::after {
			content: "";
			position: absolute;
			display: block;
			width: 5px;
			top: ((420px / 2) + 2px);
			bottom: 0;
			left: calc(50% - 2px);
			background-color: rgba($white, .7);

			@include medium {
				content: none;
			}
		}
	}

	@include medium {
		background-image: none; 

		&:not(:last-of-type) {
			margin-bottom: 80px;
		}
	}

	@include tablets {
		position: relative;
	}

	@include l-phones {
		position: static;
		flex-direction: column-reverse;
	}
}

.timeline__info {
	flex: 0 0 50%;

	@include medium {
		flex: 0 0 calc(100% - 250px);
		margin-top: 80px;
	}

	@include tablets {
		flex: 1 1 100%;
		margin-top: 25px;
	}
}

.timeline__title {
	@extend .h1;
	position: relative;
	display: block;
	margin-top: -25px;

	@include medium {
		display: inline;
		margin-top: 0;
		background: $white;
	}

	@include tablets {
		display: inline-block;
		width: calc(100vw - 300px);
		z-index: 10;

		background: none;
	}

	@include l-phones {
		display: block;
		width: 100%;
	}

	&::after {
		content: "";
		position: absolute;
		display: block;
		width: 30px;
		height: 30px;
		top: 9px;
		left: auto;
		right: -17px;
		border: 2px solid $banner;
		border-radius: 50%;
		background: $white;
		z-index: 1;

		@include medium {
			content: none;
		}
	}
}

.timeline__info,
.timeline__title {
	text-align: right;

	@include medium {
		text-align: left;
	}

	@include l-phones {
		text-align: center;
	}
}

.timeline__title,
.timeline__text,
.timeline__proof {
	padding: 0 40px;

	@include medium {
		padding: 0;
	}
}

.timeline__text {
	font-family: $os;
	font-size: 18px;
	line-height: 1.5;

	p {
		margin: 1em 0;
	}

	@include medium {
		margin-top: 40px;
	}

	@include tablets {
		margin-top: 140px;
	}

	@include l-phones {
		margin-top: 0;
	}
}

.timeline__proof {
	& > *:last-child {
		font-weight: bold;
	}
}

.timeline__pic {
	position: relative;
	display: block;

	@include medium {
		flex: 0 0 250px;
		margin-top: 0;
	}

	@include tablets {
		position: absolute;
		top: 0;
		left: auto;
		right: 0;
	}

	@include l-phones {
		position: static;
		flex: 1 1 100%;
	}

	img {
		display: block;
		width: 420px;
		max-width: 100%;
		margin: auto;
		margin-top: 0;
		margin-bottom: 80px;
		border-radius: 50%;
		border: 1px solid $grey;

		position: relative;
		transform: scale3d(0, 0, 0);

		transition: all 1s ease-in-out;

		&.show {
			transform: scale3d(1, 1, 1);
		}

		@include medium {
			width: 220px;
			margin: 0;
			margin-left: auto;
		}

		@include l-phones {
			width: 100%;
			max-width: 300px;
			margin: auto;
		}
	}
	
	&::before {
		content: "";
		position: absolute;
		display: block;
		height: 2px;
		top: (420px / 2);
		left: 0;
		right: 50%;
		background-color: $grey;

		z-index: -1;

		@include medium {
			height: 2px;
			top: 106px;
			left: -50vw;
			right: 100px;
		}

		@include tablets {
			content: none;
		}
	}
}
