.page-name {
	width: 100%;
	margin-top: 75px;
	padding-top: 55px;

	font-size: 32px;
	font-family: $ci;
	font-style: italic;
	text-align: center;

	background-image: url(../img/decor/logo.png);
	background-repeat: no-repeat;
	background-position: 50% 0;
}