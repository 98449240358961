.footer {
	position: relative;
	display: block;
	font-size: 12px;
	line-height: 2;
	background-color: $footer;
	color: $white;

}

.footer__wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	padding: $basic-mrg 9% 20px;

	@include large {
		padding: $basic-mrg 0 20px;
	}
}

.footer__item {
	flex-grow: 1;
	flex-shrink: 1;

	.h6 { margin-bottom: 25px; }
}

// total - 8 parts => 1 part = 100% / 8 = 12.5%
$p: 12.5%;
.footer__item_p8 { flex-basis: $p * 8; @extend .footer__item; }
.footer__item_p4 { flex-basis: $p * 4; @extend .footer__item; }
.footer__item_p3 { flex-basis: $p * 3; @extend .footer__item; }
.footer__item_p2 { flex-basis: $p * 2; @extend .footer__item; }

@include medium {
	.footer__socials,
	.footer__form     { order: 10; }
	.footer__nav,
	.footer__contacts { order: 20; }
	.footer__about    { order: 30; }
	.footer__copy     {order: 40; }

	.footer__nav,
	.footer__contacts,
	.footer__about,
	.footer__copy  { flex-basis: 50%; }
}

@include tablets {
	.footer__form { order: 9; }

	.footer__socials,
	.footer__form,
	.footer__nav,
	.footer__contacts,
	.footer__copy  { flex-basis: 100%; }

	.footer__about { display: none; }
}

// first line
.footer__socials,
.footer__form {
	height: 110px;
	//overflow: hidden;
	padding-bottom: $small-mgr;
	border-bottom: 2px solid $sub;

	@include tablets { padding-bottom: $small-mgr / 2; }
}

.footer__form {
	@include tablets { border-bottom: none; }
}

// second line
.footer__nav,
.footer__contacts,
.footer__about {
	margin-top: $small-mgr / 2;
}

// about text
.footer__about {

	& > div { padding-right: 80px; }
}

// menu 
.footer__nav {
	a:not([class^=button]) {
		color: $white;
		text-decoration: none;

		&:hover { text-decoration: underline; }
	}

	a[class^=button] {
		flex-grow: 0;
		flex-basis: 110px;
		align-self: flex-start;
		margin-top: 10px;
	}

	a:nth-of-type(4) { flex-grow: 0;}
}

// last line
.footer__copy {
	margin-top: $small-mgr;
	text-align: center;
	font-size: 13px;

	@include medium {
		text-align: left;
	}

	@include tablets {
		text-align: center;
	}
}

