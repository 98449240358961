.doors {
	display: block;
	background: $footer;
	color: $white;

	a,
	a:hover {
		color: $white;
		text-decoration: none;
	}
}

// title for the entireblock
.doors__title {
	@extend .h1;
	padding: 0 230px $small-mgr;

	@include large {
		padding-left: 0;
		padding-right: 0;
	}
}


.doors__name,
.doors__text,
.doors__foot {
	margin: 0;
	padding: 0;
}
// titile for the one "door"
.doors__name {
	padding-left: 30px;
    padding-right: 30px;
	font-size: 25px;
}

// text on the "door"
.doors__text {
	padding-left: 48px;
    padding-right: 33px;
	font-family: $os;
	font-size: 15px;
	font-weight: bold;
	text-transform: none;
	text-align: left;

	@include large {
		padding: 30px 0;
		text-align: center;
	}
}

// text at the bottom of the "door"
.doors__foot {
	font-size: 18px;
}


// container for "doors"
.doors__cont {
	width: 100%;
	display: flex;
	justify-content: space-between;

	@include large {
		justify-content: center;
		margin-top: -15px;
	}

	@include medium {
		flex-wrap: wrap;
	}
}

// "door jamb"
.doors__item {
	flex: 0 0 340px;
	height: 570px;
	padding: 28px 28px 29px 27px;
	background-image: url(../img/decor/door0.png),
					  linear-gradient(to bottom, #3d3636 62px, rgba(#3d3636, 0) 10px),
					  linear-gradient(-80deg, #4a4242 72%, #3d3636 0%);
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 100% 100%;

	-webkit-perspective: 800px;
		    perspective: 800px;

	&:hover {
		.doors__flip {
			transform: rotateY(-25deg);
			transition: transform .7s ease-in-out;
		}
	}

	@include large {
		flex: 0 0 30%;
		height: auto;
		margin: 15px;
		background-color: #3d3636;
		background-image: linear-gradient(-80deg, #4a4242 72%, #3d3636 0%);
		background-position: 0 0;
		transition: all .7s ease-in-out;

		&:hover {
			background-position: 200px 100%;
			transition: all .7s ease-in-out;
			.doors__flip {
				transform: none;
			}
		}
	}

	@include medium {
		flex: 0 1 500px;
		margin: 15px 0;
		padding: 15px;
	}
}

// the "door" itself (the tag <a>)
.doors__flip {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	height: 100%;
	padding-bottom: 17px;
    padding-top: 30px;
	font-family: $os;
	font-weight: 800;
	text-transform: uppercase;
	text-align: center;
	background-image: url(../img/decor/door1.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	transform-origin: right center;
	transform: rotateY(0);
	transition: transform .7s ease-in-out;

	@include large {
		padding: 0;
		background: none;
	}
}