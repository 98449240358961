.retro-checks {
	display: block;
	font-size: 18px;
	font-family: $os;
	font-weight: bold;
	text-align: left;
	color: $black;

	.label {
		font-size: 18px;
		font-family: $os;
		color: $black;
	}

	.checkbox-custom {
		margin-top: 7px;
	}

	.radio:checked ~ .label,
	.checkbox:checked ~ .label {
		font-style: italic;
	}

	.checkbox:checked + .checkbox-custom::before {
		color: $banner;
	}
}