$image: 260px;

// container
.teaser-block {
	@extend .section_sm;
	padding: 40px 3%;
	text-align: center;
}


// teaser
.teaser {
	display: flex;
	width: 100%;
	margin: 40px 0;
	text-align: left;
	text-decoration: none;
	color: $black;

	&:hover {
		.h4 { color: $banner; }
	}

	@include medium { flex-wrap: wrap; }
}

.teaser__img {
	flex: 0 1 50%;
	overflow: hidden;

	img { width: 100%; }

	@include medium { flex: 1 1 200px; }
	@include tablets { flex: 1 1 40%; }
	@include phones { flex-basis: 100%; }
}

.teaser__cont {
	flex: 1 1 70%;
	padding: 0 25px;
	color: $black;

	@include medium { flex: 1 1 250px; }
	@include tablets { flex: 1 1 60%; }
	@include phones { flex-basis: 100%; }

	p {
		margin: 8px 0;
	}
}

.teaser__author {
	display: flex;
	align-items: center;
	margin-top: 6px;
	text-decoration: none;
}


// text
.teaser__date,
.teaser__name {
	@extend %semi-bold;
	font-size: 16px;
	color: $grey;
}

.teaser__date {
	padding-bottom: 5px;
}

.teaser__name {
	font-style: italic;
	padding-left: 15px;
}

// avatar
.teaser__ava {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	flex-shrink: 0;
}

// link
.teaser__link {
	@extend .link;
}