// Table "Retro"
.table-retro {
	margin: auto;
	margin-top: 30px;
	margin-bottom: 0;
	font-size: 16px;
	text-align: center;
	border-collapse: collapse;

	th, td {
		line-height: 1;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	th {
		font-size: 18px;
		font-weight: bold;
	}

	@include l-phones {
		th, td {
			font-size: 14px;
			padding-top: 5px;
			padding-bottom: 5px;
		}
	}
}

.table-retro_fixed {
	table-layout: fixed;
	width: 100%;

	th { width: 50%; }
}

.table-retro__ps {
	font-size: 12px;
	margin-top: 5px;
	margin-bottom: 40px;
}

.table-retro__tr_underline {
	& > td,
	& > th {
		border-bottom: 1px solid;
	}
}

.table-retro__tr_topline {
	& > td,
	& > th {
		border-top: 1px solid;
	}
}

