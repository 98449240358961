.dropdown {
	display: none;

	@include large { display: block; }
}

.dropdown__block {
	@include large {
		display: none;

		&.show {
			display: flex;
			width: 100%;
			border-top: 2px solid rgba($banner, .4);
		}
	}

	@include tablets {
		&.show {
			flex-direction: column;
		}
	}
}
