$d: 195px;


.infolinks {
	@extend .blocks_inline;
	width: 100%;

	background: linear-gradient($accent 2px, transparent 0);
	background-size: 100% 2px;
	background-color: $white;
	background-repeat: no-repeat;
	background-position: 50% 50%;

	@include large {
		background-size: 90% 2px;
	}

	@include medium {
		flex-wrap: wrap;
		justify-content: center;
		background: none;
	}

	@include phones {
		background: linear-gradient(90deg, transparent 50%, $accent 2px, transparent calc(50% + 2px));
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: 100% 80%;
	}
}

.infolinks__item {
	position: relative;
	display: block;
	width: $d;
	height: $d;
	padding: 14px;
	padding-top: 115px;
	background-color: $white;
	box-shadow: 0px 0 0 15px $white;
	border-radius: 50%;
	border-width: 2px;
	border-style: solid;
	border-color: $white;
	transition: border-color .5s;
	color: $black;
	text-decoration: none;
	text-align: center;
	overflow: hidden;

	/*&_beginn { @include addSprite(55%, -$sprite-i1-y + 20px); }
	&_success{ @include addSprite(55%, -$sprite-i2-y + 20px); }
	&_pre    { @include addSprite(50%, -$sprite-i3-y + 20px); }
	&_full   { @include addSprite(55%, -$sprite-i4-y + 20px); }*/

	background-repeat: no-repeat;
	background-position: 50% 20px;

	&:hover {
		border-color: $accent;
		transition: border-color .9s;
	}

	@include large {
		transform: scale(.8);
		box-shadow: none;
	}

	@include medium {
		flex-basis: $d;
		margin: 15px 50px;
		transform: scale(1);
	}

	@include tablets { margin: 15px; }

	@include phones  { margin: 30px 15px; }
}

a.infolinks__item {
	color: $black;
	
	&::after {
		content: "learn more";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding-top: 80px;
		color: $accent;
		font-size: 0;
		font-family: $os;
		font-weight: 800;
		text-decoration: none;
		text-transform: uppercase;
		text-align: center;
		border-radius: 50%;
		background-color: rgba($white, .8);
		opacity: 0;
		transition: all .5s ease-in-out;
	}
	&:hover {
		&::after {
			font-size: 22px;
			opacity: 1;
			transition: all .5s ease-in-out;
		}
	}
}

// is mobile
.infolinks__item_mobile {
	border-radius: 0;
	border: none;
	height: auto;
}

a.infolinks__item_mobile {
	&::after {
		content: "learn more";
		display: block;
		position: static;
		font-size: 14px;
		padding-top: 0;
		color: $accent;
		font-family: $os;
		font-weight: 800;
		text-decoration: none;
		text-transform: uppercase;
		text-align: center;
		background-color: none;
		opacity: 1;
	}

	&:hover {
		&::after {
			@include phones {
				font-size: 14px;
			}
		}
	}
}