.figure_main {
	max-width: 900px;
	margin: 0 auto;
}

.figure__img {
	display: block;
	width: 100%;
}

.figure__caption {
	padding: 70px;
	text-align: center;
	font-family: $os;
	font-size: 24px;
	background-color: $arblock;

	h3 {
		@extend .team__name;
	}

	p {
		margin: 0;
		margin-top: 20px;
		font-size: 24px;
	}

	@include tablets {
		padding: 7vw 6vw;
		font-size: 18px;

		p {
			font-size: 20px;
		}
	}
}