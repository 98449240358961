.wrap-content {
	width: 100%;
	padding-left: calc((100% - 1270px) / 2);
	padding-right: calc((100% - 1270px) / 2);

	@include x-large {
		padding-left: 6%;
		padding-right: 6%;
	}

	@include phones {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.wrap-content_left {
	width: 100%;
	padding-left: calc((100vw - 1270px) / 2);

	@include x-large {
		padding-left: 6%;
	}

	@include tablets { padding-right: 6%; }

	@include phones {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.wrap-content_right {
	width: 100%;
	padding-right: calc((100vw - 1270px) / 2);

	@include x-large {
		padding-right: 6%;
	}

	@include tablets { padding-left: 6%; }

	@include phones {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.wrap-content_right-md {
	width: 100%;
	padding-right: calc((100vw - 1270px) / 2);

	@include x-large {
		padding-right: 6%;
	}

	@include medium { padding-left: 6%; }

	@include phones {
		padding-left: 15px;
		padding-right: 15px;
	}
}