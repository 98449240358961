.blog-slider {
	width: 100%;
	max-width: 600px;

	&:hover {
		.swiper-button-prev,
		.swiper-button-next {
			&::before {
				color: rgba($color: $banner, $alpha: 1);
			}
		}
	}
}

// image
.swiper-slide > img {
	display: block;
	max-width: 100%;
	width: 100%;
	margin: auto;
}

// buttons
.swiper-button-prev,
.swiper-button-next {
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;

	&::before {
		font-family: $fa;
		font-size: 50px;
		color: rgba($color: $banner, $alpha: .6);
		transition: color .3s ease-in;

		@include phones {
			font-size: 35px;
		}
	}
}

.swiper-button-next {
	&::before {
		content: "\f105";
	}

	@include phones {
		right: 0;
	}
}


.swiper-button-prev {
	&::before {
		content: "\f104";
	}

	@include phones {
		left: 0;
	}
}

// dots 
.swiper-pagination-bullet        { background: $banner; }
.swiper-pagination-bullet-active { background: $banner; }

// scrollbar
.swiper-scrollbar      { background: rgba($color: $banner, $alpha: .2); }
.swiper-scrollbar-drag { background: rgba($color: $banner, $alpha: .5); }

.swiper-container-horizontal > .swiper-scrollbar {
    left: 0%!important;
    width: 100%!important;
}