.h1 {
	font-family: $g;
	font-size: 55px;
	line-height: 1;
	text-transform: uppercase;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;

	@include phones { font-size: 36px; }
}

.h1_smaller {
	@include tablets { font-size: 44px; }
	@include phones  { font-size: 30px; }
}

.h2 {
	font-family: $g;
	font-size: 40px;
	line-height: 1;
	text-transform: uppercase;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;

	@include tablets { font-size: 30px; }
	@include phones { font-size: 28px; }
}

.h3 {
	font-family: $os;
	font-weight: 800;
	font-size: 27px;
	line-height: 1.5;
	text-transform: uppercase;
	text-align: center;
	margin-top: 0;
	margin-bottom: 0;

	@include tablets { font-size: 24px; }
	@include phones { font-size: 20px; }
}

.h4 {
	font-family: $os;
	font-weight: 700;
	font-size: 22px;
	line-height: 1.5;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 0;
}

.h5 {
	font-family: $os;
	font-weight: 700;
	font-size: 20px;
	line-height: 1;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 0;
}

.h6 {
	font-family: $os;
	font-weight: 700;
	font-size: 13px;
	line-height: 1;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 0;
}

.h-group {
	margin: 20px 0;
	font-family: $os;
	font-weight: 700;
	font-size: 20px;
	text-transform: uppercase;
}

.h-block {
	@extend .text_big;
	@extend .text_upper;
	@extend .text_bold;

	font-family: $g;
}


.h_topline {
	position: relative;
	padding-top: 20px;
	margin-top: 70px;

	&::before {
		content: "";
		position: absolute;
		display: block;
		width: 75px;
		height: 1px;
		top: 0;
		left: 50%;
		transform: translate3d(-50%, 0, 0);
		background-color: $black;
	}

	@include tablets { margin-top: 30px; }
}


// new font 
.h_cochin {
	font-family: $ci;
}