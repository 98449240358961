$padding-top: 15px;

.accordeon {
	position: relative;
	margin: $basic-mrg 0;
}

.accordeon__item {
	position: relative;
	margin-bottom: 5px;
	font-size: 0;
	overflow: hidden;

	box-shadow: 0 0 0 1px  rgba($sub, .5);

	&:not(:last-child) { margin-bottom: 55px; }

	&.active {
		.accordeon__title {
			background: $white;
			color: $accent;
			//border-bottom: none;

			& > *[class^=h]::after {
				content: "–";
				padding-bottom: 6px;
				padding-right: 3px;
			}

			& > .text-note { font-size: 0; }
		}
		.accordeon__body {
			height: auto;
			//padding-top: $padding-top;
			//border: 1px solid rgba($sub, .5);
			//border-top: none;
		}
	}
}

.accordeon__title {
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	font-size: 16px;
	padding: 50px;
	padding-left: 40px;
	//border: 1px solid rgba($sub, .5);
	transition: all .2s ease-in;

	& > * { text-align: left; }

	& > *[class^=h] {
		position: relative;
		padding-right: 30%;

		&::after {
			content: "+";
			display: inline-block;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate3d(0, -50%, 0);
		}

		@include tablets {
			padding-right: 10%;
		}
	}

	&:hover {
		background-color: $accent;
		transition: all .2s ease-in;
		color: $white;
		cursor: pointer;

		& > .text-note {
			color: rgba($white, .7);
		}
	}

	@include tablets {
		padding: 20px;
		padding-left: 25px;
	}
}

.accordeon__body {
	height: 0;
	padding-bottom: 0;
	padding-top: 0;
	font-size: 24px;

	& > * {
		margin: 0 50px;
	}

	& > * > p {
		margin-top: 0;
		//margin-bottom: 70px;
		margin-bottom: 30px;
	}

	//& > p { padding: 1rem 0; }

	#sendForm {
		font-size: 20px;
	}

	@include tablets {
		& > * { margin: 0 10px; }
	}

	@include phones { font-size: 16px; }
}


.accordeon__body > * { position: absolute; }
.accordeon__item.active > .accordeon__body > * { position: static; }

// text
.accordeon__p {
	margin-top: 0;
	margin-bottom: 70px;
}