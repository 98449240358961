.link {
	text-decoration: none;
	color: $banner;
	font-weight: bold;

	transition: all .3s ease-in;

	&:hover {
		text-decoration: underline;
	}
}

.link_white {
	color: $white;
	text-decoration: none;

	&::before { display: inline-block; }

	&:hover {
		color: $white;
		text-decoration: underline;
	}
	&:hover::before { text-decoration: none; }
}

.link_inherit {
	@extend .link;
	color: inherit;

	&:hover { color: inherit; }
}

.link_d {
	@extend .link;
	
	color: inherit;
	border-bottom: 1px dotted;

	&:hover {
		color: $banner;
		text-decoration: none;
	}
}

.link-more {
	text-decoration: none;
	color: $black;
	font-weight: bold;
	transition: color .2s ease;

	&:hover {
		color: $banner;
	}
}

.link-tel {
	text-decoration: none;
	color: inherit;
	cursor: default;

	&:hover {
		text-decoration: none;
		color: inherit;
	}
}